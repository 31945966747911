import { find, intersection, isEmpty } from 'lodash-es';

import storeAndPersistor from '../../storeConfig';
import { UserRole } from '../constants/appConstants';

import { getCurrentUser } from './UserSelectors';

export enum Role {
    CanAccessBackOffice = 'CanAccessBackOffice',
    CanAddUser = 'CanAddUser',
    CanViewPurchaseOrders = 'CanViewPurchaseOrders',
    CanEditPurchaseOrders = 'CanEditPurchaseOrders',
    CanDeletePurchaseOrders = 'CanDeletePurchaseOrders',
    CanViewAllPurchaseOrders = 'CanViewAllPurchaseOrders',
    CanLinkPurchaseOrderToInvoice = 'CanLinkPurchaseOrderToInvoice',
    CanLinkInvoiceToPurchaseOrder = 'CanLinkInvoiceToPurchaseOrder',
    CanAddPurchaseOrders = 'CanAddPurchaseOrders',
    CanViewDashboard = 'CanViewDashboard',
    CanViewDashboardPurchaseOrders = 'CanViewDashboardPurchaseOrders',
    CanViewInvoiceRegister = 'CanViewInvoiceRegister',
    CanApprove = 'CanApprove',
    CanUseAutomation = 'CanUseAutomation',
    CanUpdateInvoiceHeaderSensitiveData = 'CanUpdateInvoiceHeaderSensitiveData',
    CanOnlyViewInvoices = 'CanOnlyViewInvoices',
    CanAddOrEditInvoiceSupplier = 'CanAddOrEditInvoiceSupplier',
    CanChangeInvoiceSupplier = 'CanChangeInvoiceSupplier',
    CanChangeInvoiceBuyerCompany = 'CanChangeInvoiceBuyerCompany',
    CanApproveInvoiceWithoutNextConfirmer = 'CanApproveInvoiceWithoutNextConfirmer',
    CanViewCompanySettings = 'CanViewCompanySettings',
    CanViewRoles = 'CanViewRoles',
    CanExportInvoice = 'CanExportInvoice',
    CanChangeAccountDistributionItemAnyTime = 'CanChangeAccountDistributionItemAnyTime',
    CanApproveAndViewAnyInvoice = 'CanApproveAndViewAnyInvoice',
    CanAddInvoice = 'CanAddInvoice',
    CanUpdateInvoice = 'CanUpdateInvoice',
    CanUpdateInvoiceHeader = 'CanUpdateInvoiceHeader',
    CanViewInvoice = 'CanViewInvoice',
    CanViewArchive = 'CanViewArchive',
    CanApproveInvoice = 'CanApproveInvoice',
    CanChangeInvoiceStatus = 'CanChangeInvoiceStatus',
    CanDeleteInvoice = 'CanDeleteInvoice',
    CanAddAccountDistributionItem = 'CanAddAccountDistributionItem',
    CanUpdateAccountDistributionItem = 'CanUpdateAccountDistributionItem',
    CanAddInvoiceFiles = 'CanAddInvoiceFiles',
    CanUpdateInvoiceFiles = 'CanUpdateInvoiceFiles',
    CanAddAutoConvertTemplate = 'CanAddAutoConvertTemplate',
    CanUpdateAutoConvertTemplate = 'CanUpdateAutoConvertTemplate',
    CanViewAutoConvertTemplate = 'CanViewAutoConvertTemplate',
    CanModifySuperUsers = 'CanModifySuperUsers',
    CanUseSearch = 'CanUseSearch',
    CanViewInvoiceToConfirm = 'CanViewInvoiceToConfirm',
    CanViewNewInvoices = 'CanViewNewInvoices',
    CanViewCalendar = 'CanViewCalendar',
    CanChangeExportSettings = 'CanChangeExportSettings',
    CanViewApproversInvoices = 'CanViewApproversInvoices',
    CanViewApproversInvoicesNoAdmin = 'CanViewApproversInvoicesNoAdmin',
    CanViewAssignerInvoices = 'CanViewAssignerInvoices',
    CanViewWorkflowAddBefore = 'CanViewWorkflowAddBefore',
    CanViewWorkflowAddAfter = 'CanViewWorkflowAddAfter',
    CanViewWorkflowAddCompleter = 'CanViewWorkflowAddCompleter',
    CanAddTransactionRows = 'CanAddTransactionRows',
    CanSeeStatusNewConfirmButton = 'CanSeeStatusNewConfirmButton',
    CanSeeStatusNewConfirmAndAssignButton = 'CanSeeStatusNewConfirmAndAssignButton',
    CanSeeStatusNewRejectButton = 'CanSeeStatusNewRejectButton',
    CanSeeStatusNewJustAssignButton = 'CanSeeStatusNewJustAssignButton',
    CanSeeStatusAssignedConfirmButton = 'CanSeeStatusAssignedConfirmButton',
    CanSeeStatusAssignedConfirmAndAssignButton = 'CanSeeStatusAssignedConfirmAndAssignButton',
    CanSeeStatusAssignedRejectButton = 'CanSeeStatusAssignedRejectButton',
    CanSeeStatusAssignedJustAssignButton = 'CanSeeStatusAssignedJustAssignButton',
    CanViewWorkflowEdit = 'CanViewWorkflowEdit',
    CanImportXLS = 'CanImportXLS',
    CanSeeCombineMenu = 'CanSeeCombineMenu',
    CanInvoiceConfirmationFlowAssign = 'CanInvoiceConfirmationFlowAssign',
    CanInvoiceConfirmationFlowConfirm = 'CanInvoiceConfirmationFlowConfirm',
    CanInvoiceConfirmationFlowConfirmAndAssign = 'CanInvoiceConfirmationFlowConfirmAndAssign',
    CanInvoiceConfirmationFlowReject = 'CanInvoiceConfirmationFlowReject',
    CanInvoiceConfirmationFlowSkip = 'CanInvoiceConfirmationFlowSkip',
    CanSeeInvoiceConfirmationFlowMoreActions = 'CanSeeInvoiceConfirmationFlowMoreActions',
    CanInvoiceConfirmationFlowEdit = 'CanInvoiceConfirmationFlowEdit',
    CanInvoiceConfirmationFlowAddBeforeAfterAlways = 'CanInvoiceConfirmationFlowAddBeforeAfterAlways',
    CanInvoiceConfirmationFlowAddBeforeAfterCurrentStep = 'CanInvoiceConfirmationFlowAddBeforeAfterCurrentStep',
    CanViewSettingsUsers = 'CanViewSettingsUsers',
    CanViewSettingsCompany = 'CanViewSettingsCompany',
    CanViewSettingsConfitmationFlows = 'CanViewSettingsConfitmationFlows',
    CanEditUserSettings = 'CanEditUserSettings',
    CanViewInvoiceTypes = 'CanViewInvoiceTypes',
    CanViewPaymentMethods = 'CanViewPaymentMethods',
    CanResendActivationEmail = 'CanResendActivationEmail',
}

/*
  0 = Admin/Administraator,
  1 = Processor/Approver/Menetleja,
  2 = ProcessOrganizer/Assigner/Menetluse korraldaja/Raamatupidaja,
  3 = ProcessEnder/Completing workflow/Menetluse lõpetaja,
  5 = Auditor/Audiitor,
  8 = ExportManager,
  101 = SystemAdmin/BO Admin
  */

const store = storeAndPersistor.store;

/**
 * This array holds only the relevant userRoles for the InvoiceDetails views InvoiceConfirmations component
 */
export const invoiceConfirmationsRoles = [UserRole.Assigner, UserRole.Accountant, UserRole.Processor, UserRole.CompletingWorkflow, UserRole.Administrator];

/**
 * Check if the user has only the required roles
 * @param providedRoleList
 */
export function userHasOnlyProvidedConfirmationsRoles(providedRoleList: number[]): boolean {
    const userData = getCurrentUser(store.getState());
    if (userData && userData.hasOwnProperty('MemberRoles') && userData.MemberRoles) {
        // extract only relevant roles for InvoiceConfirmations component
        const memberRoles: number[] = userData.MemberRoles.filter((role) => invoiceConfirmationsRoles.includes(role));
        if (memberRoles.length > 0 && providedRoleList.length > 0 && memberRoles.length === providedRoleList.length) {
            return providedRoleList.reduce((prev, curr) => {
                if (memberRoles.indexOf(curr) === -1) {
                    return false;
                }
                return prev;
            }, true);
        }
    }
    return false;
}

export function hasUserARole(role: UserRole): boolean {
    const userMemberRoles = getCurrentUser(store.getState())?.MemberRoles || [];
    return userMemberRoles.includes(role);
}

export function hasOnlyOneRole(role: UserRole, userData = getCurrentUser(store.getState())): boolean {
    const userMemberRoles = userData?.MemberRoles || [];
    return userMemberRoles.length === 1 && userMemberRoles.includes(role);
}

export function checkUserOnlyRoleExpenseCreator() {
    return hasOnlyOneRole(UserRole.ExpenseCreator);
}

export function getCurrentUserData() {
    return getCurrentUser(store.getState());
}

/**
 * ideally the userData should be provided to guarantee re-rendering of components when userData changes
 * current returned result may remain outdated I think (not able to produce this case at the moment)
 * @param role
 * @param userData
 */
export function isAuthorized(role: string | Role, userData = getCurrentUser(store.getState())): boolean {
    if (userData && userData.hasOwnProperty('MemberRoles') && userData.MemberRoles) {
        switch (role) {
            case Role.CanInvoiceConfirmationFlowEdit:
                return (
                    userHasOnlyProvidedConfirmationsRoles([UserRole.Assigner]) ||
                    userHasOnlyProvidedConfirmationsRoles([UserRole.Assigner, UserRole.Processor]) ||
                    userHasOnlyProvidedConfirmationsRoles([UserRole.Assigner, UserRole.Processor, UserRole.CompletingWorkflow]) ||
                    isAuthorized(Role.CanApproveAndViewAnyInvoice)
                );
            case Role.CanInvoiceConfirmationFlowAddBeforeAfterAlways:
                return (
                    userHasOnlyProvidedConfirmationsRoles([UserRole.Assigner, UserRole.Processor]) ||
                    userHasOnlyProvidedConfirmationsRoles([UserRole.Assigner, UserRole.Processor, UserRole.CompletingWorkflow]) ||
                    isAuthorized(Role.CanApproveAndViewAnyInvoice)
                );
            case Role.CanInvoiceConfirmationFlowAddBeforeAfterCurrentStep:
                return (
                    userHasOnlyProvidedConfirmationsRoles([UserRole.Processor]) ||
                    userHasOnlyProvidedConfirmationsRoles([UserRole.Assigner, UserRole.Processor]) ||
                    userHasOnlyProvidedConfirmationsRoles([UserRole.Processor, UserRole.CompletingWorkflow]) ||
                    userHasOnlyProvidedConfirmationsRoles([UserRole.Assigner, UserRole.Processor, UserRole.CompletingWorkflow]) ||
                    isAuthorized(Role.CanApproveAndViewAnyInvoice)
                );
            case Role.CanSeeInvoiceConfirmationFlowMoreActions:
                return (
                    !userHasOnlyProvidedConfirmationsRoles([UserRole.Assigner]) &&
                    !userHasOnlyProvidedConfirmationsRoles([UserRole.Processor]) &&
                    !isEmpty(intersection([UserRole.Administrator, UserRole.Accountant, UserRole.Processor, UserRole.Assigner, UserRole.CompletingWorkflow], userData.MemberRoles))
                );
            case Role.CanInvoiceConfirmationFlowAssign:
                return !isEmpty(intersection([UserRole.Administrator, UserRole.Accountant, UserRole.Assigner], userData.MemberRoles));
            case Role.CanInvoiceConfirmationFlowConfirmAndAssign:
                return !isEmpty(intersection([UserRole.Administrator, UserRole.Accountant, UserRole.Processor, UserRole.Assigner, UserRole.CompletingWorkflow], userData.MemberRoles));
            case Role.CanInvoiceConfirmationFlowReject:
                return !isEmpty(intersection([UserRole.Administrator, UserRole.Accountant, UserRole.Processor, UserRole.Assigner, UserRole.CompletingWorkflow], userData.MemberRoles));
            case Role.CanInvoiceConfirmationFlowConfirm:
                return !isEmpty(intersection([UserRole.Administrator, UserRole.Accountant, UserRole.Processor, UserRole.Assigner, UserRole.CompletingWorkflow], userData.MemberRoles));
            case Role.CanInvoiceConfirmationFlowSkip:
                return !isEmpty(intersection([UserRole.Administrator, UserRole.Accountant, UserRole.Processor, UserRole.Assigner, UserRole.CompletingWorkflow], userData.MemberRoles));
            case Role.CanAccessBackOffice:
                return userData.MemberRoles.includes(UserRole.SystemAdmin);
            case Role.CanSeeStatusNewConfirmButton:
            case Role.CanViewCompanySettings:
                return !isEmpty(intersection([UserRole.Administrator, UserRole.Accountant], userData.MemberRoles));
            case Role.CanViewRoles:
                return !isEmpty(intersection([UserRole.Administrator], userData.MemberRoles));
            case Role.CanViewArchive:
                return !isEmpty(intersection([UserRole.ArchiveViewer], userData.MemberRoles));
            case Role.CanOnlyViewInvoices:
                return isEmpty(intersection([UserRole.Accountant, UserRole.Administrator, UserRole.Assigner, UserRole.Processor], userData.MemberRoles));
            case Role.CanChangeExportSettings:
                return !isEmpty(intersection([UserRole.ExportManager], userData.MemberRoles));
            case Role.CanViewAllPurchaseOrders:
                return !isEmpty(intersection([UserRole.PurchaseOrdersAdministrator], userData.MemberRoles));
            case Role.CanLinkPurchaseOrderToInvoice:
                return !checkUserOnlyRoleExpenseCreator();
            case Role.CanLinkInvoiceToPurchaseOrder:
                return (
                    !isEmpty(intersection([UserRole.PurchaseOrdersAdministrator, UserRole.Administrator], userData.MemberRoles)) ||
                    !isEmpty(intersection([UserRole.PurchaseOrdersAdministrator, UserRole.Accountant], userData.MemberRoles))
                );
            case Role.CanAddPurchaseOrders:
                return !isEmpty(intersection([UserRole.PurchaseOrdersAdministrator, UserRole.PurchaseOrdersCreator], userData.MemberRoles));
            case Role.CanApprove:
            case Role.CanViewApproversInvoices:
            case Role.CanViewDashboardPurchaseOrders:
                return !isEmpty(intersection([UserRole.Processor, UserRole.Administrator, UserRole.Accountant, UserRole.PurchaseOrdersAdministrator], userData.MemberRoles));
            case Role.CanModifySuperUsers:
                return !isEmpty(intersection([UserRole.SystemAdmin], userData.MemberRoles));
            case Role.CanApproveInvoiceWithoutNextConfirmer:
            case Role.CanUseAutomation:
                return !isEmpty(intersection([UserRole.Administrator, UserRole.Accountant, UserRole.CompletingWorkflow], userData.MemberRoles));
            case Role.CanUpdateInvoiceHeaderSensitiveData:
                return !isEmpty(intersection([UserRole.Administrator, UserRole.Accountant, UserRole.Assigner, UserRole.Processor], userData.MemberRoles));
            case Role.CanAddOrEditInvoiceSupplier:
                return !isEmpty(
                    intersection(
                        [UserRole.Administrator, UserRole.Accountant, UserRole.Assigner, UserRole.CompletingWorkflow, UserRole.InvoiceRegisterAdministrator, UserRole.Processor],
                        userData.MemberRoles,
                    ),
                );
            case Role.CanAddAccountDistributionItem:
            case Role.CanChangeAccountDistributionItemAnyTime:
            case Role.CanChangeInvoiceBuyerCompany:
            case Role.CanChangeInvoiceSupplier:
            case Role.CanSeeStatusNewConfirmAndAssignButton:
            case Role.CanSeeStatusNewJustAssignButton:
            case Role.CanViewAssignerInvoices:
            case Role.CanViewNewInvoices:
            case Role.CanViewWorkflowEdit:
                return !isEmpty(intersection([UserRole.Administrator, UserRole.Accountant, UserRole.Assigner], userData.MemberRoles));
            case Role.CanUseSearch:
                return !isEmpty(intersection([UserRole.Administrator, UserRole.Accountant, UserRole.Assigner, UserRole.Processor], userData.MemberRoles));
            case Role.CanSeeStatusAssignedConfirmAndAssignButton:
            case Role.CanSeeStatusAssignedConfirmButton:
            case Role.CanSeeStatusAssignedRejectButton:
            case Role.CanViewCalendar:
            case Role.CanViewInvoiceToConfirm:
                return !isEmpty(intersection([UserRole.Administrator, UserRole.Accountant, UserRole.Processor], userData.MemberRoles));
            case Role.CanAddUser:
            case Role.CanChangeInvoiceStatus:
                return !isEmpty(intersection([UserRole.Administrator, UserRole.Accountant, UserRole.CompletingWorkflow], userData.MemberRoles));
            case Role.CanApproveAndViewAnyInvoice:
            case Role.CanDeleteInvoice:
                return !isEmpty(intersection([UserRole.Administrator, UserRole.Accountant, UserRole.SystemAdmin, UserRole.CompletingWorkflow], userData.MemberRoles));
            case Role.CanAddInvoice:
                return !isEmpty(intersection([UserRole.Administrator, UserRole.Accountant, UserRole.Assigner, UserRole.ExpenseCreator, UserRole.InvoiceRegisterAdministrator], userData.MemberRoles));
            case Role.CanApproveInvoice:
            case Role.CanUpdateAccountDistributionItem:
            case Role.CanViewWorkflowAddAfter:
            case Role.CanViewWorkflowAddBefore:
            case Role.CanViewWorkflowAddCompleter:
                return !isEmpty(intersection([UserRole.Administrator, UserRole.Accountant, UserRole.Assigner, UserRole.Processor], userData.MemberRoles));
            case Role.CanImportXLS:
                return !isEmpty(intersection([UserRole.Administrator, UserRole.Accountant, UserRole.CompletingWorkflow, UserRole.Processor], userData.MemberRoles));
            case Role.CanViewPurchaseOrders:
                return !isEmpty(intersection([UserRole.Processor, UserRole.PurchaseOrdersAdministrator, UserRole.PurchaseOrdersCreator, UserRole.Auditor], userData.MemberRoles));
            case Role.CanEditPurchaseOrders:
                return !isEmpty(intersection([UserRole.Processor, UserRole.PurchaseOrdersAdministrator, UserRole.PurchaseOrdersCreator], userData.MemberRoles));
            case Role.CanDeletePurchaseOrders:
                return !isEmpty(intersection([UserRole.PurchaseOrdersAdministrator, UserRole.PurchaseOrdersCreator], userData.MemberRoles));
            case Role.CanExportInvoice:
                return !isEmpty(intersection([UserRole.Administrator, UserRole.Accountant, UserRole.Assigner, UserRole.Auditor, UserRole.Processor], userData.MemberRoles));
            case Role.CanAddAutoConvertTemplate:
            case Role.CanAddInvoiceFiles:
            case Role.CanUpdateAutoConvertTemplate:
            case Role.CanUpdateInvoiceFiles:
            case Role.CanViewAutoConvertTemplate:
                return !isEmpty(intersection([UserRole.Administrator, UserRole.Accountant, UserRole.Assigner, UserRole.CompletingWorkflow, UserRole.Processor], userData.MemberRoles));
            case Role.CanUpdateInvoice:
            case Role.CanUpdateInvoiceHeader:
                return !isEmpty(intersection([UserRole.Administrator, UserRole.Accountant, UserRole.Assigner, UserRole.InvoiceRegisterAdministrator, UserRole.Processor], userData.MemberRoles));
            case Role.CanViewInvoiceRegister:
            case Role.CanViewInvoice:
                return !isEmpty(
                    intersection(
                        [UserRole.Administrator, UserRole.Accountant, UserRole.Assigner, UserRole.Auditor, UserRole.InvoiceRegisterAdministrator, UserRole.ExpenseCreator, UserRole.Processor],
                        userData.MemberRoles,
                    ),
                );
            case Role.CanViewDashboard:
                return !isEmpty(
                    intersection(
                        [UserRole.Administrator, UserRole.Accountant, UserRole.Assigner, UserRole.CompletingWorkflow, UserRole.InvoiceRegisterAdministrator, UserRole.Processor],
                        userData.MemberRoles,
                    ),
                );
            case Role.CanAddTransactionRows:
                return !isEmpty(
                    intersection(
                        [UserRole.Administrator, UserRole.Accountant, UserRole.Assigner, UserRole.CompletingWorkflow, UserRole.InvoiceRegisterAdministrator, UserRole.Processor, UserRole.SystemAdmin],
                        userData.MemberRoles,
                    ),
                );
            case Role.CanViewApproversInvoicesNoAdmin:
                return (
                    userData.MemberRoles.find(function(r) {
                        return [UserRole.Auditor, UserRole.Processor].includes(r);
                    }) != null &&
                    userData.MemberRoles.find(function(r) {
                        return [UserRole.Administrator].includes(r);
                    }) == null &&
                    userData.MemberRoles.find(function(r) {
                        return [UserRole.Accountant].includes(r);
                    }) == null &&
                    userData.MemberRoles.find(function(r) {
                        return [UserRole.Assigner].includes(r);
                    }) == null
                );
            case Role.CanSeeStatusAssignedJustAssignButton:
            case Role.CanSeeStatusNewRejectButton:
            case Role.CanSeeCombineMenu:
                return !!userData.MemberRoles.filter((r) => ![UserRole.Auditor].includes(r)).length;
            case Role.CanViewSettingsCompany:
                return !isEmpty(intersection([UserRole.Administrator, UserRole.SystemAdmin], userData.MemberRoles));
            case Role.CanViewSettingsUsers:
                return !isEmpty(intersection([UserRole.Administrator], userData.MemberRoles));
            case Role.CanViewSettingsConfitmationFlows:
                return !isEmpty(intersection([UserRole.Administrator], userData.MemberRoles));
            case Role.CanViewInvoiceTypes:
                return !isEmpty(intersection([UserRole.Administrator], userData.MemberRoles));
            case Role.CanViewPaymentMethods:
                return !isEmpty(intersection([UserRole.Administrator, UserRole.Accountant], userData.MemberRoles));
            case Role.CanResendActivationEmail:
                return !isEmpty(intersection([UserRole.Administrator], userData.MemberRoles));
            default:
                return false;
        }
    }
    return false;
}

export function isSystemSetting(settingName: string, userData = getCurrentUser(store.getState())) {
    if (userData && userData.CompanySettings) {
        const setting = find(userData.CompanySettings, function(s) {
            return s === settingName;
        });

        return !!setting;
    }
    return false;
}
