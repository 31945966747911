import { push, replace } from 'connected-react-router';
import { isEmpty, isNumber } from 'lodash-es';
import { createAction } from 'redux-actions';

import { getCurrentUserGroupMember, updateUserSettings } from '../../common/user/UserActions';
import { getViewUserSearchParams, getViewUserSettings, ListViewUserConfig, setUserSettingValue } from '../../common/user/userSettingUtil';
import { validateAndFixPagingOptions, validateAndFixSortItems } from '../../common/utils/baseSearchHelpers';
import { paths, resolvePathParams } from '../../common/router/routePaths';
import { getCurrentUser } from '../../common/user/UserSelectors';
import { FileReaderResult } from '../../common/utils/fileReader';
import { loadableDataActions, loadableDataActionsWithRequest } from '../../common/utils/LoadableData';
import { notify } from '../../common/utils/notify';
import i18n from '../../i18n';
import { GlobalState } from '../../rootReducer';
import api from '../../services/ApiServices';
import {
    BaseSearch,
    GroupMemberApproverDTO,
    PagedListContainer,
    PurchaseOrderFileAttachmentDTO,
    PurchaseOrderHeaderHistoryDTO,
    PurchaseOrdersDTO,
    PurchaseOrdersRowsDTO,
    PurchaseOrderTaskAction,
    PurchaseOrderTaskActionDto,
    PurchaseOrderTaskDTO,
    PurchaseOrderUpdateExtraStatusDTO,
    SearchType,
    SortDirection,
    UserSettingName,
} from '../../services/types/ApiTypes';
import { DispatchThunk } from '../../storeConfig';
import { DEFAULT_RESTRICTION } from '../purchase-orders/PurchaseOrdersViewHelper';
import { PurchaseOrdersAddViewEmptyRowFields, PurchaseOrdersAddViewFields } from './purchaseOrderAddViewFields';
import { HistorySearchParams } from './PurchaseOrdersAddViewReducer';
import { selectPurchaseOrderHistorySearchParams } from './PurchaseOrdersAddViewSelectors';
import { getPurchaseOrderHistory } from './components/HistoryCard/PurchaseOrderHistoryActions';

const ns = 'purchase-orders/';

const historyListViewConfig: ListViewUserConfig = {
    sortDir: UserSettingName.PURCHASE_ORDER_HISTORY_SORT_DIRECTION,
    sortCol: UserSettingName.PURCHASE_ORDER_HISTORY_SORT_COLUMN,
    pageSize: UserSettingName.PURCHASE_ORDER_HISTORY_PAGE_SIZE,
};

export const retrievePOActions = loadableDataActions<string, PurchaseOrdersDTO>(`${ns}RETRIEVE_PURCHASE_ORDER`);
export const getPurchaseOrderHistoryLoadable = loadableDataActionsWithRequest<HistorySearchParams, PagedListContainer<PurchaseOrderHeaderHistoryDTO>>(`${ns}ALL_HISTORY`);

export const getHistoryList = (searchParams: BaseSearch, poId: number) => {
    return async (dispatch: DispatchThunk, getState: () => GlobalState) => {
        if (isEmpty(getState().user.groupMemberCommonLoadable.payload)) {
            await dispatch(getCurrentUserGroupMember());
        }

        const historySearchParams = searchParams || selectPurchaseOrderHistorySearchParams(getState());
        const groupMember = getState().user.groupMemberCommonLoadable.payload;

        const viewSearchParams = getViewUserSearchParams(historySearchParams, historyListViewConfig, groupMember);
        viewSearchParams.PagingOptions = validateAndFixPagingOptions(viewSearchParams.PagingOptions);
        viewSearchParams.SortItems = validateAndFixSortItems(viewSearchParams.SortItems, 'AuditDate', SortDirection.Asc);

        const apiSearchParams = { ...historySearchParams };
        apiSearchParams.Restrictions = [
            {
                Field: 'Id',
                Value: poId,
                FieldSearchType: 0,
                Values: null,
            },
            {
                Field: 'language',
                Value: i18n.language,
                FieldSearchType: 0,
                Values: null,
            },
            ...apiSearchParams.Restrictions,
        ];
        let response;
        try {
            dispatch(getPurchaseOrderHistoryLoadable.request(historySearchParams));
            response = await api.purchaseOrder.getPurchaseOrderHeaderHistory(apiSearchParams);
            if (response?.status === 200) {
                dispatch(
                    getPurchaseOrderHistoryLoadable.success({
                        request: historySearchParams,
                        result: response.data,
                    }),
                );
            } else {
                notify.error(i18n.t('interceptorsFactory.ErrorWhileProcessingData'), i18n.t('interceptorsFactory.Error'));
            }
        } catch (e) {
            dispatch(
                getPurchaseOrderHistoryLoadable.error({
                    request: historySearchParams,
                    result: e,
                }),
            );
        }
    };
};

export function addOrUpdatePO(values: PurchaseOrdersAddViewFields) {
    return async (dispatch: DispatchThunk, getState: () => GlobalState) => {
        const state = getState();
        let response;
        try {
            dispatch(retrievePOActions.request(String(values.Id)));
            const request = createPurchaseOrdersDTO(values);
            const oldPO = state.purchaseOrdersAdd.purchaseOrder;
            if (!request.OrderNumber || !oldPO) {
                return;
            }
            response = await api.purchaseOrder.savePurchaseOrder({
                ...oldPO,
                ...request,
            });
            if (response.data) {
                dispatch(retrievePOActions.success(response.data));
                notify.success(i18n.t('view.PurchaseOrders.Header.SavedSuccessfully'));
            } else {
                notify.error(i18n.t('view.PurchaseOrders.Header.ErrorSaving'));
            }
        } catch (e) {
            console.error(e);
            if (e.response?.data?.ErrorCode) {
                notify.error(i18n.t(e.response.data.ErrorCode));
            } else {
                notify.error(i18n.t('view.PurchaseOrders.Header.ErrorSaving'));
            }
            dispatch(retrievePOActions.error(e));
        }
    };
}

function createPurchaseOrdersDTO(values: PurchaseOrdersAddViewFields): PurchaseOrdersDTO {
    return {
        DecisionDate: null,
        Description: values.Description,
        DateCreated: values.Issued,
        OrderNumber: values.PoNumber,
        OrderType: values.Type,
        SupplierId: values.Supplier && values.Supplier.value ? values.Supplier.value.Id : null,
        Currency: values.Currency.value,
        IsManualFulfilment: false,
        RemainingSum: 0,
    };
}

export function retrievePO(id?: string) {
    return async (dispatch: DispatchThunk) => {
        let response;
        try {
            dispatch(retrievePOActions.request(id));
            if (id) {
                response = await api.purchaseOrder.getPurchaseOrderById(id);
            } else {
                response = await api.purchaseOrder.createNewPurchaseOrder();
            }
            if (response.data) {
                dispatch(retrievePOActions.success(response.data));
                dispatch(retrievePOTaskItems(response.data));
            } else {
                notify.info(i18n.t('view.PurchaseOrders.Error.General'));
                dispatch(push('/dashboard'));
            }
        } catch (e) {
            console.error(e);
            if (e.response?.data?.ErrorCode) {
                notify.error(i18n.t(e.response.data.ErrorCode));
            }
            dispatch(retrievePOActions.error(e));
            dispatch(push('/dashboard'));
        }
    };
}

export const updateSums = createAction<{ totalSum: number; totalSumWithoutVat?: number }>(`${ns}UPDATE_PO_ORDER_SUMS`);

export const retrievePORowsActions = loadableDataActions<string, PurchaseOrdersRowsDTO[]>(`${ns}RETRIEVE_PO_ROW_ITEMS`);

export function retrievePORows(id?: string) {
    return async (dispatch: DispatchThunk) => {
        let response;
        try {
            dispatch(retrievePORowsActions.request(id));
            if (id) {
                response = await api.purchaseOrder.getPurchaseOrderRows(id);
            }
            if (response.data) {
                dispatch(retrievePORowsActions.success(response.data));
            } else {
                notify.info(i18n.t('interceptorsFactory.ErrorWhileProcessingData'));
            }
        } catch (e) {
            console.error(e);
            if (e.response?.data?.ErrorCode) {
                notify.error(i18n.t(e.response.data.ErrorCode));
            }
            dispatch(retrievePORowsActions.error(e));
        }
    };
}

export const updateRow = createAction<{ result: PurchaseOrdersRowsDTO | PurchaseOrdersAddViewEmptyRowFields; itemToUpdate?: number }>(`${ns}UPDATE_ROW`);

export const updatePoRow = (id: number, item: PurchaseOrdersRowsDTO) => {
    return async (dispatch: DispatchThunk) => {
        try {
            if (item?.Id > 0) {
                dispatch(
                    updateRow({
                        result: item,
                        itemToUpdate: item?.Id,
                    }),
                );
            }
            const result = await api.purchaseOrder.savePurchaseOrderRow(id, item);

            if (!item?.Id) {
                dispatch(
                    updateRow({
                        result: result.data.PurchaseOrdersRowDto,
                    }),
                );
            }
            if (result?.data?.PurchaseOrderSumWithoutVat || result?.data?.PurchaseOrderTotal) {
                dispatch(
                    updateSums({
                        totalSum: result?.data?.PurchaseOrderTotal || 0,
                        totalSumWithoutVat: result?.data?.PurchaseOrderSumWithoutVat || 0,
                    }),
                );
            }
        } catch (e) {
            console.error(e);
            if (e?.response?.data?.ErrorCode) {
                notify.error(i18n.t(e.response.data.ErrorCode));
            } else {
                notify.info(i18n.t('view.Accounting.RowSavingFailed'));
            }
        }
    };
};

export const removeRow = createAction<number>(`${ns}REMOVE_ROW`);

export const deletePoRow = (item: PurchaseOrdersRowsDTO) => {
    return async (dispatch: DispatchThunk) => {
        try {
            dispatch(removeRow(item?.Id));
            const result = await api.purchaseOrder.deletePurchaseOrderRow(item);

            if (isNumber(result?.data?.PurchaseOrderSumWithoutVat) || isNumber(result?.data?.PurchaseOrderTotal)) {
                dispatch(
                    updateSums({
                        totalSum: result?.data?.PurchaseOrderTotal || 0,
                        totalSumWithoutVat: result?.data?.PurchaseOrderSumWithoutVat || 0,
                    }),
                );
            }
        } catch (e) {
            console.error(e);
            if (e?.response?.data?.ErrorCode) {
                notify.error(i18n.t(e.response.data.ErrorCode));
            } else {
                notify.info(i18n.t('view.Accounting.RowSavingFailed'));
            }
        }
    };
};

export const retrievePOFilesActions = loadableDataActions<string, PurchaseOrderFileAttachmentDTO[]>(`${ns}RETRIEVE_PO_FILES_ITEMS`);

export function retrievePOFiles(id?: string) {
    return async (dispatch: DispatchThunk) => {
        let response;
        try {
            dispatch(retrievePOFilesActions.request(id));
            if (id) {
                response = await api.purchaseOrder.getPurchaseOrderRelatedDocuments(id);
            }
            if (response.data) {
                dispatch(retrievePOFilesActions.success(response.data));
            } else {
                notify.info(i18n.t('view.PurchaseOrders.Error.General'));
            }
        } catch (e) {
            console.error(e);
            if (e.response?.data?.ErrorCode) {
                notify.error(i18n.t(e.response.data.ErrorCode));
            }
            dispatch(retrievePOFilesActions.error(e));
        }
    };
}

export const retrievePOTaskItemsActions = loadableDataActions<PurchaseOrdersDTO, PurchaseOrderTaskDTO[]>(`${ns}RETRIEVE_PO_TASK_ITEMS`);

export function retrievePOTaskItems(purchaseOrder: PurchaseOrdersDTO) {
    return async (dispatch: DispatchThunk) => {
        try {
            dispatch(retrievePOTaskItemsActions.request(purchaseOrder));
            const response = await api.purchaseOrder.getPurchaseOrderTaskItems(purchaseOrder.Id);
            dispatch(retrievePOTaskItemsActions.success(response.data));
        } catch (e) {
            console.error(e);
            if (e.response?.data?.ErrorCode) {
                notify.error(i18n.t('e.response.data.ErrorCode'));
            }
            dispatch(retrievePOTaskItemsActions.error(e));
        }
    };
}

export const uploadFileActions = loadableDataActions<string, string>(`${ns}UPLOAD_FILE`);
export const uploadFileProgress = createAction<number>(`${ns}UPLOAD_FILE_PROGRESS`);

export function uploadFile(fileReaderResult: FileReaderResult) {
    return async (dispatch: DispatchThunk, getState: () => GlobalState) => {
        try {
            dispatch(uploadFileActions.request(fileReaderResult.fileName));
            const poId = getState().purchaseOrdersAdd.purchaseOrder.Id;
            const response = await api.purchaseOrder.uploadPurchaseOrderInvoiceFile(
                {
                    Base64Content: fileReaderResult.base64Content,
                    Extension: undefined,
                    FileName: fileReaderResult.fileName,
                    FileUrl: undefined,
                    Id: undefined,
                    IsNew: undefined,
                    PathToFile: undefined,
                    PurchaseOrderId: getState().purchaseOrdersAdd.purchaseOrder.Id,
                    UserFullName: undefined,
                    ModifiedById: getCurrentUser(getState()).GroupMemberId,
                    UploadedDateTime: new Date().toISOString(),
                },
                (progress) => {
                    dispatch(uploadFileProgress(progress));
                },
            );
            dispatch(uploadFileActions.success(response.data));
            dispatch(retrievePOFiles(String(poId)));
        } catch (e) {
            console.error(e);
            dispatch(uploadFileActions.error(e));
        }
    };
}

export const downloadFileActions = loadableDataActions<PurchaseOrderFileAttachmentDTO, string>(`${ns}DOWNLOAD_FILE`);

export function downloadFile(file: PurchaseOrderFileAttachmentDTO) {
    return async (dispatch: DispatchThunk) => {
        try {
            dispatch(downloadFileActions.request(file));
            const response = await api.purchaseOrder.downloadPurchaseOrderFile(file.Id);
            const blob = new Blob([response.data]);
            window.saveAs(blob, file.FileName);
            dispatch(downloadFileActions.success(file.FileName));
        } catch (e) {
            console.error(e);
            dispatch(downloadFileActions.error(e));
        }
    };
}

export const openFileInNewWindowAction = createAction<number>(`${ns}OPEN_FILE_IN_NEW_WINDOW`);

export function openFileInNewWindow(fileId: number) {
    return async (dispatch: DispatchThunk) => {
        try {
            dispatch(openFileInNewWindowAction(fileId));
            const fileWindow = window.open('', '_blank', 'toolbar=yes,scrollbars=yes,resizable=yes');
            const response = await api.purchaseOrder.getPurchaseOrderFileToken(fileId);
            fileWindow.location.href = window.location.origin + '/WebApi/api/File/PreviewPurchaseOrderFileByToken?id=' + response.data;
        } catch (e) {
            console.error(e);
        }
    };
}

export const deleteFileActions = loadableDataActions<PurchaseOrderFileAttachmentDTO, string>(`${ns}DOWNLOAD_FILE`);

export function deleteFile(file: PurchaseOrderFileAttachmentDTO) {
    return async (dispatch: DispatchThunk) => {
        try {
            dispatch(deleteFileActions.request(file));
            const response = await api.purchaseOrder.deletePurchaseOrderFile(file);
            dispatch(deleteFileActions.success(response.data));
            dispatch(retrievePOFiles(String(file.PurchaseOrderId)));
        } catch (e) {
            console.error(e);
            dispatch(deleteFileActions.error(e));
        }
    };
}

export const deletePurchaseOrderLoadable = loadableDataActions(`${ns}DELETE_PURCHASE_ORDER`);

export function deletePurchaseOrder(PurchaseOrderId: number) {
    return async (dispatch: DispatchThunk) => {
        try {
            dispatch(deletePurchaseOrderLoadable.request(PurchaseOrderId));
            const response = await api.purchaseOrder.deletePurchaseOrder(PurchaseOrderId);
            dispatch(deletePurchaseOrderLoadable.success(response.data));
            dispatch(retrievePO(String(PurchaseOrderId)));
        } catch (e) {
            console.error(e);
            dispatch(deletePurchaseOrderLoadable.error(e));
        }
    };
}

export const doTaskActionActions = loadableDataActions<any, any>(`${ns}DO_TASK_ACTION`);

export function doTaskAction(workflowId: number, comment: string, taskAction: PurchaseOrderTaskAction) {
    return async (dispatch: DispatchThunk, getState: () => GlobalState) => {
        try {
            dispatch(doTaskActionActions.request({ workflowId, comment }));
            const poId = getState().purchaseOrdersAdd.purchaseOrder.Id;
            const groupMemberId = getCurrentUser(getState()).GroupMemberId;
            const request: PurchaseOrderTaskActionDto = {
                Comment: comment,
                GroupMemberId: groupMemberId,
                PurchaseOrder: undefined,
                PurchaseOrderId: poId,
                TaskAction: taskAction,
                Tasks: undefined,
                WorkflowTemplateId: workflowId,
            };
            const response = await api.purchaseOrder.doTaskAction(request);
            dispatch(doTaskActionActions.success(response.data));
            dispatch(retrievePO(String(poId)));
        } catch (e) {
            console.error(e);
            dispatch(doTaskActionActions.error(e));
        }
    };
}

export const addApproverActions = loadableDataActions<any, any>(`${ns}ADD_APPROVER`);

export function addApprover(approver: GroupMemberApproverDTO, orderNo: number) {
    return async (dispatch: DispatchThunk, getState: () => GlobalState) => {
        try {
            const purchaseOrder = getState().purchaseOrdersAdd.retrievePOLoadable.payload;
            dispatch(addApproverActions.request(approver));
            const response = await api.purchaseOrder.insertTaskAction(purchaseOrder.Id, approver.Id, orderNo);
            dispatch(addApproverActions.success(response.data));
            dispatch(retrievePOTaskItemsActions.success(response.data.Tasks));
        } catch (e) {
            console.error(e);
            notify.info(e.message, 'Failed to add approver.');
            dispatch(addApproverActions.error(e));
        }
    };
}

export function replaceUrlToDetails() {
    return (dispatch: DispatchThunk, getState: () => GlobalState) => {
        try {
            const poId = getState().purchaseOrdersAdd.purchaseOrder.Id;
            dispatch(replace(resolvePathParams(paths.app.purchaseOrderDetails, { id: poId })));
        } catch (e) {
            console.error(e);
        }
    };
}

export const updateExtraStatusActions = loadableDataActions<PurchaseOrderUpdateExtraStatusDTO, number>(`${ns}UPDATE_EXTRA_STATUS`);
export function updateExtraStatus(extraStatus: number, isManualFulfilment: boolean) {
    return async (dispatch: DispatchThunk, getState: () => GlobalState) => {
        try {
            const poId = getState().purchaseOrdersAdd.purchaseOrder.Id;
            const request: PurchaseOrderUpdateExtraStatusDTO = {
                PurchaseOrderId: poId,
                OrderExtraStatus: extraStatus,
                IsManualFulfilment: isManualFulfilment,
            };
            dispatch(updateExtraStatusActions.request(request));
            await api.purchaseOrder.updateExtraStatus(request);
            dispatch(updateExtraStatusActions.success(extraStatus));
            dispatch(retrievePO(String(poId)));
            dispatch(getPurchaseOrderHistory(poId));
        } catch (e) {
            console.error(e);
            dispatch(updateExtraStatusActions.error(e));
        }
    };
}

export const setPoHistoryPagingOptions = (poId: number, page?: number, pageSize?: number) => {
    return async (dispatch: DispatchThunk, getState: () => GlobalState) => {
        const {
            purchaseOrdersAdd,
            user: {
                groupMemberCommonLoadable: { payload: groupMember },
            },
        } = getState();
        const paging = purchaseOrdersAdd.searchParams.PagingOptions;

        if (!isEmpty(groupMember)) {
            const viewConfig = getViewUserSettings(historyListViewConfig, groupMember);
            if (!viewConfig.pageSize || parseInt(viewConfig.pageSize, 10) !== pageSize) {
                groupMember.UserSettings = setUserSettingValue(historyListViewConfig.pageSize, pageSize, groupMember.UserSettings);
            }
            dispatch(updateUserSettings(groupMember.Id, groupMember.UserSettings));
        }

        const searchParams: HistorySearchParams = {
            ...purchaseOrdersAdd.searchParams,
            PagingOptions: {
                Page: !pageSize || (pageSize && pageSize === paging.Count) ? page : 1,
                Count: pageSize && pageSize !== paging.Count ? pageSize : paging.Count,
            },
        };
        dispatch(getHistoryList(searchParams, poId));
    };
};

export const sortPoHistoryList = (columnName: string, poId: number) => {
    return async (dispatch: DispatchThunk, getState: () => GlobalState) => {
        const {
            purchaseOrdersAdd,
            user: {
                groupMemberCommonLoadable: { payload: groupMember },
            },
        } = getState();
        const sorting = purchaseOrdersAdd.searchParams.SortItems[0];
        const sortingDirection = sorting.SortColumn === columnName ? (sorting.SortDirection === SortDirection.Asc ? SortDirection.Desc : SortDirection.Asc) : SortDirection.Asc;

        if (!isEmpty(groupMember)) {
            const viewConfig = getViewUserSettings(historyListViewConfig, groupMember);

            if (!viewConfig.sortCol || viewConfig.sortCol !== columnName) {
                groupMember.UserSettings = setUserSettingValue(historyListViewConfig.sortCol, columnName, groupMember.UserSettings);
            }
            if (!viewConfig.sortDir || parseInt(viewConfig.sortDir, 10) !== sortingDirection) {
                groupMember.UserSettings = setUserSettingValue(historyListViewConfig.sortDir, sortingDirection, groupMember.UserSettings);
            }
            dispatch(updateUserSettings(groupMember.Id, groupMember.UserSettings));
        }

        const searchParams: BaseSearch = {
            ...purchaseOrdersAdd.searchParams,
            SortItems: [
                {
                    SortColumn: columnName,
                    SortDirection: sorting.SortColumn === columnName ? (sorting.SortDirection === SortDirection.Asc ? SortDirection.Desc : SortDirection.Asc) : SortDirection.Asc,
                },
            ],
        };
        dispatch(getHistoryList(searchParams, poId));
    };
};

export const searchPoHistoryList = (searchString: string, poId: number) => {
    return async (dispatch: DispatchThunk, getState: () => GlobalState) => {
        const state = getState();
        const paging = state.purchaseOrdersAdd.searchParams.PagingOptions;
        let searchRestriction = state.purchaseOrdersAdd.searchParams.Restrictions.find((r) => r.Field === DEFAULT_RESTRICTION);
        const otherRestrictions = state.purchaseOrdersAdd.searchParams.Restrictions.filter((restriction, index) => index !== 0);
        let restrictions = [];

        if (!isEmpty(searchString)) {
            if (!searchRestriction) {
                searchRestriction = {
                    Field: DEFAULT_RESTRICTION,
                    Value: searchString,
                    Values: null,
                    FieldSearchType: SearchType.NotSelected,
                };
            } else {
                searchRestriction.Value = searchString;
            }
            restrictions.push(searchRestriction);
        }

        if (!isEmpty(otherRestrictions)) {
            restrictions = [...restrictions, ...otherRestrictions];
        }
        const searchParams: HistorySearchParams = {
            ...state.purchaseOrdersAdd.searchParams,
            PagingOptions: {
                ...paging,
                Page: 1,
            },
            Restrictions: [...restrictions],
        };
        dispatch(getHistoryList(searchParams, poId));
    };
};
