import { DispatchThunk } from '../../../storeConfig';
import api from '../../../services/ApiServices';
import { loadableDataActions } from '../../../common/utils/LoadableData';
import { BackOfficeUserDTO } from '../../../services/types/BoApiTypes';

const ns = 'back-office/common/users/';

export const getBoCurrentUserLoadableAction = loadableDataActions<null, BackOfficeUserDTO>(`${ns}GET_BACK_OFFICE_CURRENT_USER`);

export function getBoCurrentUser() {
    return async (dispatch: DispatchThunk) => {
        try {
            dispatch(getBoCurrentUserLoadableAction.request(null));
            const boCurrentUserResponse = await api.boUsers.getCurrentUser();
            dispatch(getBoCurrentUserLoadableAction.success(boCurrentUserResponse.data));
        } catch (e) {
            console.error(e);
            dispatch(getBoCurrentUserLoadableAction.error(e));
        }
    };
}
