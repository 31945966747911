import { createAction } from 'redux-actions';
import { loadableDataActions } from '../../common/utils/LoadableData';
import { notify } from '../../common/utils/notify';
import i18nInstance from '../../i18n';
import api from '../../services/ApiServices';
import { SupplierDTO } from '../../services/types/ApiTypes';
import { DispatchThunk } from '../../storeConfig';

const ns = 'new-supplier-modal/';

export const setActiveSupplier = createAction<SupplierDTO>(`${ns}SET_ACTIVE_SUPPLIER`);
export const addSupplierActions = loadableDataActions<SupplierDTO, SupplierDTO>(`${ns}ADD_SUPPLIER`);
export const updateSupplierActions = loadableDataActions<SupplierDTO, SupplierDTO>(`${ns}UPDATE_SUPPLIER`);

export const addOrUpdateSupplier = (supplier: SupplierDTO) => {
    return async (dispatch: DispatchThunk) => {
        try {
            if (supplier.Id) {
                try {
                    dispatch(updateSupplierActions.request(supplier));
                    const response = await api.suppliers.updateSupplier(supplier);
                    dispatch(updateSupplierActions.success(response.data));
                    return Promise.resolve(response.data);
                } catch (e) {
                    dispatch(updateSupplierActions.error(e));
                    return Promise.reject(e);
                }
            } else {
                try {
                    dispatch(addSupplierActions.request(supplier));
                    const response = await api.suppliers.addSupplier(supplier);
                    dispatch(addSupplierActions.success(response.data));
                } catch (e) {
                    dispatch(addSupplierActions.error(e));
                    return Promise.reject(e);
                }
            }

            // import the actions lazily, otherwise it seems we get circular dependencies and page crashes, probably the solution would be to move GlobalState out of the rootReducer file
            import('../../views/settings/suppliers/SuppliersViewActions').then((actions) => {
                dispatch(actions.setPagingOptions(1));
            });
        } catch (e) {
            console.error(e);
            notify.error(i18nInstance.t('interceptorsFactory.ErrorWhileProcessingData'), i18nInstance.t('interceptorsFactory.Error'));
            return Promise.reject(e);
        }
    };
};

export const showEditSupplierModal = createAction<boolean>(`${ns}SHOW_NEW_SUPPLIER_MODAL`);

export function showAddOrEditSupplierModal(supplier?: SupplierDTO | Pick<SupplierDTO, 'Id'>) {
    return async (dispatch: DispatchThunk) => {
        try {
            // load the supplier data if Id is provided. If it is not provided, then we are creating from scratch
            if (supplier?.Id) {
                const response = await api.suppliers.getSupplierById(supplier.Id);
                dispatch(setActiveSupplier(response.data));
            } else {
                dispatch(setActiveSupplier(undefined));
            }
            dispatch(showEditSupplierModal(true));
        } catch (e) {
            console.error(e);
            notify.error(i18nInstance.t('interceptorsFactory.ErrorWhileSendingData'), i18nInstance.t('interceptorsFactory.Error'));
        }
    };
}
