import { ButtonProps } from './ButtonTypes';

export const rootClassName = 'ui-button';
export const useClasses = (props: Pick<ButtonProps, 'variant' | 'size' | 'color'>) => {
    const { variant, color, size } = props;
    return {
        root: rootClassName,
        fullWidth: `${rootClassName}--fullWidth`,
        disabled: `${rootClassName}--disabled-${variant}`,
        iconButton: `${rootClassName}--iconButton-${size}`,
        size: `${rootClassName}--size-${variant !== 'text' ? size : `${size}-text`}`,
        color: `${rootClassName}--variant-${variant}--color-${color}`,
        startIcon: `${rootClassName}__startIcon`,
        endIcon: `${rootClassName}__endIcon`,
        disableInteractions: `${rootClassName}--disableInteractions`,
    };
};
