import { UserCompany } from '../controllers/LoginService';
import { ConfirmationFlowStatusLookupId } from '../../common/constants/appConstants';
import { InvoicesSearchParams } from '../../views/purchase-orders-add/components/Header/InvoicesList/InvoicesListReducers';
import { PurchaseOrderNewRowFields } from '../../views/purchase-orders-new/PurchaseOrdersNewTypes';

export interface PurchaseOrdersDTO {
    CreatedById?: number;
    CreatedByName?: string;
    Currency?: string;
    CurrentlyAssignedUsers?: PurchaseOrderAssignedUser[];
    DateCreated?: Date;
    DecisionDate: Date | null;
    Description?: string;
    DocumentFiles?: PurchaseOrderFileAttachmentDTO[];
    Id?: number;
    IsLinked?: boolean;
    IsNew?: boolean;
    IsManualFulfilment: boolean;
    LinkedInvoicesCount?: number;
    LinkedInvoicesSum?: number | null;
    ModifiedBy?: number;
    OrderNumber?: string;
    OrderStatus?: PurchaseOrderStatus;
    OrderExtraStatus?: PurchaseOrderExtraStatus;
    OrderType?: PurchaseOrderType;
    PurchaseOrdersRows?: PurchaseOrdersRowsDTO[] | PurchaseOrderNewRowFields[];
    PurchaseOrderTasks?: PurchaseOrderTaskDTO[];
    RemainingSum: number;
    Total?: number;
    TotalWithoutVat?: number;
    Supplier?: SupplierDTO;
    SupplierId?: number;
}

export interface PurchaseOrderHeaderHistoryDTO {
    AuditId: number;
    AuditAction: string;
    AuditDate: Date;
    AuditApp: string;
    OrderNumber: string;
    Description: string;
    DateCreated: Date;
    OrderType: PurchaseOrderType;
    Currency: string;
    SupplierId: number;
    SupplierName: string;
    CreatedById: number;
    CreatedByName: string;
    ModifiedById: number;
    ModifiedByName: string;
    Change: PurchaseOrderHistoryChangesDTO;
}

export interface PurchaseOrderHistoryChangesDTO {
    NewValue: string;
    OldValue: string;
    Name: string;
}

export interface PurchaseOrderHeaderInvoicesDTO {
    Id: number;
    Number: string;
    InvoiceDate: Date;
    Currency: string;
    SupplierName: string;
    Status: number;
    LinkerName: string;
    LinkedDate: Date;
    SumWithoutVat: number;
    TotalAmountWithVat: number;
    IsLinked: boolean;
}

export interface InvoiceHeaderPurchaseOrdersDTO {
    Id: number;
    PurchaseOrderNumber: string;
    CreatedDate: Date;
    Currency: string;
    SupplierName: string;
    OrderStatus: number;
    LinkerName: string;
    LinkedDate: Date;
    SumWithoutVat: number;
    TotalAmountWithVat: number;
    IsLinked: boolean;
}

export interface LinkedCountDTO {
    Currency: string;
    LinkedByMeObjectsCount: number;
    LinkedObjectsCount: number;
    LinkedObjectsSumWithoutVat: number;
    LinkedObjectsTotal: number;
}

export enum PurchaseOrderType {
    Unknown = 0,
    Opex = 100,
    Capex = 200,
}

export enum PurchaseOrderStatus {
    New = 0,
    Assigned = 100,
    Confirmed = 200,
    Rejected = 300,
    Deleted = 400,
}

export interface PurchaseOrdersRowsDTO {
    PurchaseOrderId: number;
    VatCodeId: number;
    Description: string;
    Quantity: number;
    Unit: string;
    UnitPrice: number;
    SumWithoutVat: number;
    Vat: number;
    VatAmount: number;
    Total: number;
    ModifiedBy: number;
    VatCode: any; // TODO: VatCodeDTO
    Id: number;
    IsNew: boolean;
}

export interface PurchaseOrdersRowDTO {
    PurchaseOrderSumWithoutVat: number;
    PurchaseOrderTotal: number;
    PurchaseOrdersRowDto: PurchaseOrdersRowsDTO;
}

export interface PurchaseOrderTaskActionDto {
    TaskAction: PurchaseOrderTaskAction;
    Comment: string;
    PurchaseOrderId: number;
    WorkflowTemplateId: number;
    GroupMemberId: number;
    PurchaseOrder: PurchaseOrdersDTO;
    Tasks: PurchaseOrderTaskDTO[];
}

export interface PurchaseOrderUpdateExtraStatusDTO {
    OrderExtraStatus: number;
    PurchaseOrderId: number;
    IsManualFulfilment: boolean;
}

export interface PurchaseOrderTaskDTO {
    Id: number;
    PurchaseOrderId: number;
    OrderNo: number;
    MustApprove: boolean;
    ApproveCount: number;
    MaxApproveAmount: number;
    TaskAction: PurchaseOrderTaskAction;
    TaskStatus: PurchaseOrderTaskStatus;
    Comment: string;
    GroupMemberId: number;
    FirstName: string;
    LastName: string;
    ModifiedDate: Date;
    HeartBeatDateTime: Date;
    ModifiedBy: number;
    ToSubstituteName: string;
    ToSubstituteId: number;
}

export enum PurchaseOrderExtraStatus {
    AwaitingFulfillment = 0,
    Fulfilled = 100,
    PartiallyFulfilled = 200,
    Canceled = 300,
    OverFulfilled = 400,
    ImpossibleToCalculate = 500,
}

export enum PurchaseOrderTaskStatus {
    None = 0,
    Current = 100,
    Completed = 200,
}

export enum PurchaseOrderTaskAction {
    None = 0,
    Created = 100,
    Confirmed = 200,
    Skipped = 250,
    Rejected = 300,
}

export interface PurchaseOrderAssignedUser {
    UserGuid: number;
    FirstName: string;
    LastName: string;
}

export interface SortItem {
    SortColumn: string;
    SortDirection: SortDirection;
}

export enum SortDirection {
    Asc = 0,
    Desc = 1,
}

export enum SearchType {
    NotSelected = 0,
    Exact = 10,
}

export interface PagingOptions {
    Count: number;
    Page: number;
}

export interface Restriction {
    Field: string;
    Value: any;
    Values: any[];
    FieldSearchType: SearchType;
}

export interface BaseSearch {
    SortItems: SortItem[];
    PagingOptions: PagingOptions;
    Restrictions: Restriction[];
}

export interface InvoiceSearchOptions extends BaseSearch {
    IsIncludeWorkflows: boolean;
}

export interface PurchaseOrderInvoicesSearch {
    PurchaseOrderId?: number;
    SearchParams: InvoicesSearchParams;
    Filter?: PurchaseOrderInvoicesFilter;
}

export interface InvoicePurchaseOrdersSearch {
    InvoiceId?: number;
    SearchParams: InvoicesSearchParams;
    Filter?: PurchaseOrderInvoicesFilter;
}

export enum PurchaseOrderInvoicesFilter {
    Any,
    Linked,
    LinkedByMe,
    NotLinked,
}

export interface BaseSearchWithFilterValues<T, D> extends BaseSearch {
    filterValues?: { [key in keyof Partial<D>]: T };
}

export interface BaseSearchWithTableFilters<T, D> extends BaseSearch {
    filters?: { [key in keyof Partial<D>]: T };
}

export interface PagedListContainer<T> {
    Items: T[];
    Skip: number;
    Take: number;
    TotalCount: number;
    HasCount: boolean;
}

export interface ApiResultOfListOfCustomCostObjectiveDTO {
    Success: boolean;
    Messages: string[];
    Result: CustomCostObjectiveDTO[];
}

export interface ApiResultOfListOfPurchaseOrdersForInvoiceDTO {
    LinkedTotalSum: number;
    pagedListContainer: PagedListContainer<PurchaseOrdersDTO>;
    TotalLinkedOrdersCount: number;
}

export interface ApiResultOfListOfCustomCostObjectiveFullDTO {
    Success: boolean;
    Messages: string[];
    Result: CustomCostObjectiveFullDTO[];
}

export interface PurchaseOrderFileAttachmentDTO {
    PurchaseOrderId: number;
    FileName: string;
    Extension: string;
    PathToFile: string;
    Base64Content: string;
    FileUrl: string;
    ModifiedById: number;
    UserFullName: string;
    UploadedDateTime: string;
    Id: number;
    IsNew: boolean;
}

export interface WorkflowTemplateDTO {
    Name: string;
    Description: string;
    WorkflowTemplateItems: WorkflowTemplateItem[];
    Id: number;
    IsNew: boolean;
    InUse: boolean;
    IsDynamic: boolean;
    IsActive: boolean;
    ApproversPerSteps: ApproverPerStep[];
}

export interface WorkflowTemplateItem {
    ApproveCount: number;
    MaxApproveAmount: number;
    MustApprove: boolean;
    WorkflowTemplateId: number;
    OrderNo: number;
    GroupMemberId: number;
    GroupMemberName: string;
    IsWorkflowCompleter: boolean;
    Id: number;
    IsNew: boolean;
}

export interface Currency {
    Code: string;
    Description: string;
    Id: number;
    IsNew: boolean;
}

export enum AutoTransactionMatchType {
    None = -1,
    Any = 0,
    Contains = 1,
    NotEmpty = 2,
    Empty = 3,
    Equal = 4,
    NotEqual = 5,
    Less = 6,
    LessEqual = 7,
    Greater = 8,
    GreaterEqual = 9,
    Is = 10,
}

export enum CostObjectiveType {
    Account = 1,
    VatCode = 2,
    CustomCostObjective = 3,
}

export enum InvoiceFieldType {
    Text = 0,
    Dropdown = 1,
    DateTime = 2,
}

export enum WorkflowItemTypeDTO {
    AccountDistributionItem = 0,
    AccountingRow = 1,
    Invoice = 2,
}

export enum CombinationOptionType {
    CombineSearch = 10,
    CombineAll = 20,
    ApplyAll = 30,
    ApplyToAllWhere = 40,
    CombineBy = 50,
}

export enum AllocationAmountType {
    Percent = 1,
}

/**
 * GroupMemberApproversParamDTO
 * Can only have one array provided at a time, otherwise throws error
 * Provide ExcludedIds to exclude already assigned approvers from the list
 * Provide IncludedIds to only return specified approvers
 */
export interface GroupMemberApproversParamDTO {
    SearchString: string;
    ExcludeIds?: number[];
    IncludeIds?: number[];
    ApproversListDestination: ApproversListDestination;
}

export enum ApproversListDestination {
    Invoice = 0,
    PurchaseOrder = 1,
}

export interface MemberRoleDTO {
    Role: number;
    Id: number;
    IsNew: boolean;
}

export interface CompanyInvoiceCountDTO {
    CompanyGuid: string;
    Count: number;
}

export interface UserDataDTO {
    BOGuid: string;
    CurrentVersion: string;
    Email: string;
    FirstName: string;
    FullName: string;
    FullNameAndPersonalCode: string;
    GroupMemberId: number;
    GroupMemberTitle: string;
    Id: number;
    IsNew: boolean;
    Language: string;
    LastName: string;
    MemberRoles: number[];
    PersonalCode: string;
    CompanySettings: string[];
}

export interface GroupMemberMinDTO {
    Title: string;
    MembershipStart: Date;
    MembershipEnd: Date;
    UserId: number;
    IsCompanyPrimaryContact: boolean;
    Company: CompanyMinDTO;
    UserFullName: string;
    MemberRoles: MemberRoleDTO[];
    isActive: boolean;
    Substitute: SubstituteDTO;
    Id: number;
    IsNew: boolean;
}

export interface GroupMemberMicroDTO {
    UserId: number;
    Name: string;
    isActive: boolean;
    Id: number;
    IsNew: boolean;
}

export interface GroupMemberCommonDTO {
    Id: number;
    UserId: number;
    UserGuid: string;
    Name: string;
    Surname: string;
    Substitute: SubstituteDTO;
    Substitutes: SubstituteDTO[];
    MemberRoles: MemberRoleDTO[];
    UserSettings: UserSetting[];
}

export interface AutoTransactionUiDimension {
    AutoTransactionRowId: number;
    CustomCostObjectiveId: number;
    CostObjectiveId: number;
    CostObjectiveType: CostObjectiveType;
    CustomCostObjectiveDescription: string;
    CostObjectiveDescription: string;
    CostObjectiveCode: string;
    Id: number;
    IsNew: boolean;
    OrderNo?: number;
}

export interface UserDTO {
    Country?: string;
    Email: string;
    Error?: string;
    FirstName: string;
    FullName: string;
    FullNameAndPersonalCode: string;
    GroupMember: GroupMemberMinDTO;
    Id: number;
    IsActive: boolean;
    IsNew: boolean;
    LastName: string;
    Password: string;
    PersonalCode: string;
    BOGuid: string;
    Username?: string;
    EmailLastValidated?: Date;
}

export interface PasswordDTO {
    OldPassword: string;
    NewPassword: string;
    Whitelabel: string;
}

export interface UsersListWithActiveCountDTOOfUserDTO extends PagedListContainer<UserDTO> {
    ActiveUsersCount: number;
}

export interface SubstituteDTO {
    GroupMemberId: number;
    GroupMemberName: string;
    From: Date;
    To: Date;
    ToSubstituteId: number;
    ToSubstituteName: string;
    Id: number;
    IsNew: boolean;
}

export interface TaskDTO {
    WorkflowId: number;
    Completed: boolean;
    OrderNo: number;
    Comment: string;
    CompletedDate: Date;
    ProcessedBy: number;
    StatusLookupId: ConfirmationFlowStatusLookupId;
    GroupMember: GroupMemberDTO;
    GroupMemberId: number;
    ToSubstituteName: string;
    displayAddAfterMeProp: boolean;
    displayAddBeforeMeProp: boolean;
    isCurrentConfirmer: boolean;
    Id: number;
    IsNew: boolean;
    CreatedBy?: number;
    CreatorName: string;
}

export interface VatCodeDTO {
    AssignedCurrentToUser: boolean;
    AutoTransactionCountInUse: number;
    Code: string;
    EndDate: string;
    FullName: string;
    Id: number;
    InvoiceCountInUse: number;
    IsDefault: boolean;
    IsNew: boolean;
    Description: string;
    StartDate: string;
    VatRate: number;
}

export interface InvoiceCustomFieldDTO {
    Description: string;
    Code: string;
    InvoiceCustomFieldItems: InvoiceCustomFieldItemDTO[];
    CountInUse?: number;
    IsMandatory: boolean;
    IncludeInExport: boolean;
    FieldType: InvoiceFieldType;
    Id: number;
    IsNew: boolean;
}

export interface InvoiceCustomFieldItemDTO {
    Description?: string;
    InvoiceCustomFieldId: number;
    Code: string;
    Name: string;
}

export interface AutoTransactionUiAllocation {
    AllocationPercent: number;
    AllocationDescription: string;
    AllocationComment: string;
    Dimensions: AutoTransactionUiDimension[];
}

export interface GroupMemberListEntryDTO {
    Id: number;
    UserFirstName: string;
    UserLastName: string;
    UserPersonalCode: string;
    UserEmail: string;
    isActive: boolean;
    MembershipStart: Date;
    MembershipEnd: Date;
    SubstitutorFullName: string;
    IsCompanyPrimaryContact: boolean;
    MemberRoles: MemberRoleDTO[];
    UserFullName: string;
    UserFullNameAndPersonalCode: string;
}

export interface CompanyMinDTO {
    Id: number;
    RegistrationCode: string;
    Name: string;
}

export interface SimpleNameDTO {
    Id: number;
    Name: string;
}

export interface GroupMemberApproverDTO extends SimpleNameDTO {
    IsWorkflowCompleter: boolean;
}

export interface GroupMemberDTO {
    Title: string;
    Name: string;
    MembershipStart: Date;
    MembershipEnd: Date;
    PersonalCodeLastValidated?: string;
    UserId: number;
    IsCompanyPrimaryContact: boolean;
    Company: CompanyMinDTO;
    User: UserDTO;
    UserFullName: string;
    MemberRoles: MemberRoleDTO[];
    IsActive: boolean;
    Substitute: SubstituteDTO;
    Substitutes: SubstituteDTO[];
    Id: number;
    IsNew: boolean;
    UserSettings: UserSetting[];
    MonetaryLimit?: number;
    MonetaryCurrency?: string;
    SubstituterUserFullName?: string;
}

export interface UserSetting {
    Name: UserSettingName;
    Value: string;
}

export enum UserSettingName {
    ID_COUNTRY = 'Id.Country',
    ARCHIVE_PAGE_SIZE = 'Archive.PageSize',
    ARCHIVE_SORT_COLUMN = 'Archive.SortColumn',
    ARCHIVE_SORT_DIRECTION = 'Archive.SortDirection',
    AUTOMATION_PAGE_SIZE = 'Automation.PageSize',
    AUTOMATION_SORT_COLUMN = 'Automation.SortColumn',
    AUTOMATION_SORT_DIRECTION = 'Automation.SortDirection',
    AUTOMATION_RELATED_DIMENSIONS_PAGE_SIZE = 'Automation.RelatedDimensions.PageSize',
    AUTOMATION_RELATED_DIMENSIONS_SORT_COLUMN = 'Automation.RelatedDimensions.SortColumn',
    AUTOMATION_RELATED_DIMENSIONS_SORT_DIRECTION = 'Automation.RelatedDimensions.SortDirection',
    IS_EMAIL_NOTIFICATIONS_ENABLED = 'IsEmailNotificationsEnabled',
    IS_EMAIL_VERIFICATION_PENDING = 'IsEmailVerificationPending',
    INVOICE_HEADER_HISTORY_LIST_PAGE_SIZE = 'Invoice.HistoryList.PageSize',
    INVOICE_HEADER_HISTORY_LIST_SORT_COLUMN = 'Invoice.HistoryList.SortColumn',
    INVOICE_HEADER_HISTORY_LIST_SORT_DIRECTION = 'Invoice.HistoryList.SortDirection',
    INVOICE_HEADER_HISTORY_LIST_CATEGORY = 'Invoice.HistoryList.Category',
    INVOICES_PURCHASE_ORDERS_PAGE_SIZE = 'Invoice.PurchaseOrders.PageSize',
    INVOICES_PURCHASE_ORDERS_SORT_COLUMN = 'Invoice.PurchaseOrders.SortColumn',
    INVOICES_PURCHASE_ORDERS_SORT_DIRECTION = 'Invoice.PurchaseOrders.SortDirection',
    INVOICE_ROWS_PAGE_SIZE = 'Invoice.InvoiceRows.PageSize',
    INVOICE_ROWS_SORT_COLUMN = 'Invoice.InvoiceRows.SortColumn',
    INVOICE_ROWS_SORT_DIRECTION = 'Invoice.InvoiceRows.SortDirection',
    IS_INVOICE_ROWS_CALCULATION_ACTIVE = 'Invoice.InvoiceRows.IsInvoiceRowsCalculationActive',
    TRANSACTION_ROWS_PAGE_SIZE = 'Invoice.TransactionRows.PageSize',
    TRANSACTION_ROWS_SORT_COLUMN = 'Invoice.TransactionRows.SortColumn',
    TRANSACTION_ROWS_SORT_DIRECTION = 'Invoice.TransactionRows.SortDirection',
    IS_TRANSACTION_ROWS_CALCULATION_ACTIVE = 'Invoice.TransactionRows.IsTransactionRowsCalculationActive',
    PURCHASE_INVOICES_PAGE_SIZE = 'PurchaseInvoices.PageSize',
    PURCHASE_INVOICES_SORT_COLUMN = 'PurchaseInvoices.SortColumn',
    PURCHASE_INVOICES_SORT_DIRECTION = 'PurchaseInvoices.SortDirection',
    PURCHASE_ORDERS_PAGE_SIZE = 'PurchaseOrders.PageSize',
    PURCHASE_ORDERS_SORT_COLUMN = 'PurchaseOrders.SortColumn',
    PURCHASE_ORDERS_SORT_DIRECTION = 'PurchaseOrders.SortDirection',
    PURCHASE_ORDERS_COLUMNS_CONFIG = 'PurchaseOrders.ColumnsConfig',
    PURCHASE_ORDERS_TABLE_FILTERS = 'PurchaseOrders.TableFilters',
    PURCHASE_ORDER_HISTORY_PAGE_SIZE = 'PurchaseOrders.History.PageSize',
    PURCHASE_ORDER_HISTORY_SORT_COLUMN = 'PurchaseOrders.History.SortColumn',
    PURCHASE_ORDER_HISTORY_SORT_DIRECTION = 'PurchaseOrders.History.SortDirection',
    PURCHASE_ORDER_INVOICES_PAGE_SIZE = 'PurchaseOrders.Invoices.PageSize',
    PURCHASE_ORDER_INVOICES_SORT_COLUMN = 'PurchaseOrders.Invoices.SortColumn',
    PURCHASE_ORDER_INVOICES_SORT_DIRECTION = 'PurchaseOrders.Invoices.SortDirection',
    SETTINGS_ACCOUNTING_ACCOUNTS_PAGE_SIZE = 'Settings.Accounting.Accounts.PageSize',
    SETTINGS_ACCOUNTING_ACCOUNTS_SORT_COLUMN = 'Settings.Accounting.Accounts.SortColumn',
    SETTINGS_ACCOUNTING_ACCOUNTS_SORT_DIRECTION = 'Settings.Accounting.Accounts.SortDirection',
    SETTINGS_ACCOUNTING_COST_OBJECTIVES_PAGE_SIZE = 'Settings.Accounting.CostObjectives.PageSize',
    SETTINGS_ACCOUNTING_COST_OBJECTIVES_SORT_COLUMN = 'Settings.Accounting.CostObjectives.SortColumn',
    SETTINGS_ACCOUNTING_COST_OBJECTIVES_SORT_DIRECTION = 'Settings.Accounting.CostObjectives.SortDirection',
    SETTINGS_ACCOUNTING_PAGE_SIZE = 'Settings.Accounting.PageSize',
    SETTINGS_ACCOUNTING_SORT_COLUMN = 'Settings.Accounting.SortColumn',
    SETTINGS_ACCOUNTING_SORT_DIRECTION = 'Settings.Accounting.SortDirection',
    SETTINGS_ACCOUNTING_VAT_CODES_PAGE_SIZE = 'Settings.Accounting.VatCodes.PageSize',
    SETTINGS_ACCOUNTING_VAT_CODES_SORT_COLUMN = 'Settings.Accounting.VatCodes.SortColumn',
    SETTINGS_ACCOUNTING_VAT_CODES_SORT_DIRECTION = 'Settings.Accounting.VatCodes.SortDirection',
    SETTINGS_USERS_PAGE_SIZE = 'Settings.Users.PageSize',
    SETTINGS_USERS_SORT_COLUMN = 'Settings.Users.SortColumn',
    SETTINGS_USERS_SORT_DIRECTION = 'Settings.Users.SortDirection',
    SETTINGS_INVOICE_TYPES_PAGE_SIZE = 'Settings.InvoiceTypes.PageSize',
    SETTINGS_INVOICE_TYPES_SORT_COLUMN = 'Settings.InvoiceTypes.SortColumn',
    SETTINGS_INVOICE_TYPES_SORT_DIRECTION = 'Settings.InvoiceTypes.SortDirection',
    SETTINGS_ROLES_PAGE_SIZE = 'Settings.Roles.PageSize',
    SETTINGS_ROLES_SORT_COLUMN = 'Settings.Roles.SortColumn',
    SETTINGS_ROLES_SORT_DIRECTION = 'Settings.Roles.SortDirection',
    SETTINGS_WORKFLOWS_PAGE_SIZE = 'Settings.Workflows.PageSize',
    SETTINGS_WORKFLOWS_SORT_COLUMN = 'Settings.Workflows.SortColumn',
    SETTINGS_WORKFLOWS_SORT_DIRECTION = 'Settings.Workflows.SortDirection',
    SETTINGS_PAYMENT_METHODS_PAGE_SIZE = 'Settings.PaymentMethods.PageSize',
    SETTINGS_PAYMENT_METHODS_SORT_COLUMN = 'Settings.PaymentMethods.SortColumn',
    SETTINGS_PAYMENT_METHODS_SORT_DIRECTION = 'Settings.PaymentMethods.SortDirection',
    SETTINGS_PRODUCT_ITEMS_PAGE_SIZE = 'Settings.ProductItems.PageSize',
    SETTINGS_PRODUCT_ITEMS_SORT_COLUMN = 'Settings.ProductItems.SortColumn',
    SETTINGS_PRODUCT_ITEMS_SORT_DIRECTION = 'Settings.ProductItems.SortDirection',
    SETTINGS_SUPPLIERS_PAGE_SIZE = 'Settings.Suppliers.PageSize',
    SETTINGS_SUPPLIERS_SORT_COLUMN = 'Settings.Suppliers.SortColumn',
    SETTINGS_SUPPLIERS_SORT_DIRECTION = 'Settings.Suppliers.SortDirection',
    SETTINGS_PROFILE_COMPANIES_NOTIFICATIONS = 'Settings.Profile.CompaniesNotifications',
    SETTINGS_HEADER_EXTENSIONS_PAGE_SIZE = 'Settings.HeaderExtensions.PageSize',
    SETTINGS_HEADER_EXTENSIONS_SORT_COLUMN = 'Settings.HeaderExtensions.SortColumn',
    SETTINGS_HEADER_EXTENSIONS_SORT_DIRECTION = 'Settings.HeaderExtensions.SortDirection',
    BACK_OFFICE_ORGANIZATIONS_PAGE_SIZE = 'BackOffice.Organizations.PageSize',
    BACK_OFFICE_ORGANIZATIONS_SORT_COLUMN = 'BackOffice.Organizations.SortColumn',
    BACK_OFFICE_ORGANIZATIONS_SORT_DIRECTION = 'BackOffice.Organizations.SortDirection',
    BACK_OFFICE_RESELLERS_PAGE_SIZE = 'BackOffice.Resellers.PageSize',
    BACK_OFFICE_RESELLERS_SORT_COLUMN = 'BackOffice.Resellers.SortColumn',
    BACK_OFFICE_RESELLERS_SORT_DIRECTION = 'BackOffice.Resellers.SortDirection',
    BACK_OFFICE_REPORTING_PAGE_SIZE = 'BackOffice.Reporting.PageSize',
    BACK_OFFICE_REPORTING_SORT_COLUMN = 'BackOffice.Reporting.SortColumn',
    BACK_OFFICE_REPORTING_SORT_DIRECTION = 'BackOffice.Reporting.SortDirection',
    BACK_OFFICE_USERS_PAGE_SIZE = 'BackOffice.Users.PageSize',
    BACK_OFFICE_USERS_SORT_COLUMN = 'BackOffice.Users.SortColumn',
    BACK_OFFICE_USERS_SORT_DIRECTION = 'BackOffice.Users.SortDirection',
    BACK_OFFICE_USERS_FILTERS = 'BackOffice.Users.Filters',
    BACK_OFFICE_COMPANIES_PAGE_SIZE = 'BackOffice.Companies.PageSize',
    BACK_OFFICE_COMPANIES_SORT_COLUMN = 'BackOffice.Companies.SortColumn',
    BACK_OFFICE_COMPANIES_SORT_DIRECTION = 'BackOffice.Companies.SortDirection',
    BACK_OFFICE_COMPANIES_FILTERS = 'BackOffice.Companies.Filters',
}

export interface SupplierDTO {
    Id: number;
    Name: string;
    RegistrationCode: string;
    VatCode: string;
    Country: string;
    CompanyType: SerbianCompanyType;
    Status: number;
    Address: string;
    Notes: string;
    ModifiedBy: number;
    IsNew: boolean;
    ContactPersonName: string;
    Code: string;
    Email: string;
    BankAccount: string;
}

export interface ApproverPerStep {
    OrderNo: number;
    Count: number;
}
export interface WorkflowDTO {
    ApproversPerSteps?: ApproverPerStep[];
    WorkflowTemplateId: number;
    CurrentStep: number;
    CreatedById: number;
    CreatedOn: Date;
    Tasks: TaskDTO[];
    GeneratedFrom: WorkflowTemplateDTO;
    WorkflowItemType: WorkflowItemTypeDTO;
    Id: number;
    IsNew: boolean;
}

export interface SlovakianSymbolsDTO {
    InvoiceId: number;
    CustomizationId: number;
    VariableSymbol: string;
    SpecificSymbol: string;
    ConstantSymbol: string;
    DateOfTaxDuty: string;
    Reference: string;
}

export interface InvoiceCustomizationDTO {
    Id: number;
    InvoiceId: number;
    CustomizationId: number;
    Customization: InvoiceCustomFieldDTO;
    Value: string;
}

export interface InvoiceCustomizationFieldsSearchParams {
    customizationId?: number;
    customFieldItemPart?: string;
    page?: number;
    pagesize?: number;
}

export interface FileDTO {
    FileName: string;
    IsPdf: boolean;
    IsImported: boolean;
    IsOriginal?: boolean;
    InvoiceId?: number;
    WorkflowDocumentId: number;
    DownloadUrl: string;
    FileUrl: string;
    Base64Content: string;
    ForceImport: boolean;
    PathToFile: string;
    HubType: number;
    Id: number;
    IsNew: boolean;
}

export interface AutoTransactionUiVatRate {
    VatRate: number;
    Allocations: AutoTransactionUiAllocation[];
}

export interface UserMinDTO {
    GroupMemberId: number;
    Id: number;
    IsNew: boolean;
    FirstName: string;
    LastName: string;
    FullName: string;
}

export interface CompanyDataDTO {
    CompanyGuid: string;
    CompanyName: string;
    Country?: string;
    Users: UserMinDTO[];
    VatCodes: VatCodeDTO[];
    WorkflowTemplates: WorkflowTemplateDTO[];
}

export interface SerbiaCompanyData {
    Name: string;
    VatNumber: string;
    ContactPerson: string;
    Email: string;
    BankAccount: string;
}

export interface CompanyDTO {
    CompanyName: string;
    Address: string;
    RegistrationCode: string;
    VatCode?: string;
    VatRegistrationCode: string;
    CompanyGuid?: string;
    PayToAccount: string;
    DailyReportTime1: Date;
    DailyReportTime2: Date;
    Logo: Uint8Array;
    Id: number;
    IsNew: boolean;
}

export interface CompanyTimeZoneDTO {
    Id: string;
    Display: string;
}

export interface IsibVerificationDTO {
    TransactionRowId: number;
    IsibResponse: IsibResponseDTO;
}

export interface IsibResponseDTO {
    Succeeded: boolean;
}

export enum SerbianCompanyType {
    Company = 1, // PrivrednoDrustvo
    SoleProprietor = 2, // Preduzetnik
    Association = 3, // Udruzenje
    BancropcyEstate = 4, // StecajnaMasa
    Foundation = 5, // FondacijeIZaduzbine
    SportsAssociation = 6, // SportskaUdruzenja
    Chamber = 7, // Komora
    BudgetUser = 100,
}

export enum InvoiceType {
    PurchaseInvoice = 0,
    Expense = 1,
}

export interface InvoiceTypeDTO {
    Code: string;
    Description: string;
    Id: number;
    InvoiceCountInUse: number;
    IsDefault: boolean;
    IsNew: boolean;
    ModifiedBy: number;
    Type: InvoiceType;
}

export interface ItemDetailInfoDTO {
    Id: number;
    InvoiceRowId: number;
    IsNew: boolean;
    ItemAmount: string;
    ItemPrice: string;
    ItemUnit: string;
}

export interface ItemReserveDTO {
    Id: number;
    InformationContent?: string;
    InformationName?: string;
    InvoiceRowId: number;
    IsNew: boolean;
    ExtensionId?: string;
}

export interface InvoiceRowDTO {
    OrderNo: number;
    InvoiceId: number;
    Id: number;
    InvoiceCountInUse: number;
    Description: string;
    IsNew: boolean;
    SellerProductId: string;
    SerialNumber: number;
    SumWithoutVat: string;
    BuyerProductId: string;
    BuyerProductName?: string;
    CustomerRef: string;
    Total: string;
    ItemSum?: string;
    VAT: string;
    VatRate: number;
    GroupId?: string;
    TaricCode: number;
    ItemDetailInfo: ItemDetailInfoDTO[];
    ItemReserve?: ItemReserveDTO[];
    EAN?: string;
    InvoiceRowDiscounts?: InvoiceRowDiscountDTO[];
}

export interface InvoiceRowDiscountDTO {
    Id: number;
    InvoiceRowId: number;
    Type: InvoiceRowDiscountType;
    Description: string;
    Rate: number;
    Amount: number;
    IsNew: boolean;
}

export enum InvoiceRowDiscountType {
    DSC,
    CHR,
}

export interface TransactionRowExtensionsDTO {
    Id: number;
    InformationContent?: string;
    InformationName?: string;
    TransactionRowId: number;
    ExtensionId?: string;
    ModifiedBy?: number;
}

export interface TransactionRowDTO {
    AccountId: number;
    VatCodeId: number;
    VatCode: VatCodeDTO;
    Account: AccountDTO;
    AccountCode: string;
    TransactionRowsDimensions: TransactionRowDimensionDTO[];
    TransactionRowExtensions: TransactionRowExtensionsDTO[];
    SellerProductId: string;
    BuyerProductName: string;
    TaricCode: string;
    CustomerRef: string;
    EAN: string;
    Description: string;
    VAT: string;
    VatRate: number;
    Total: string;
    InvoiceId: number;
    OrderNo: number;
    ItemUnit: string;
    ItemAmount: string;
    ItemPrice: string;
    BuyerProductId: string;
    SerialNumber: string;
    SumWithoutVat: string;
    Comment: string;
    AccountDescription: string;
    AccountingDate: Date;
    Id: number;
    IsNew: boolean;
}

export interface TransactionRowHistoryDTO {
    AuditAction: string;
    AuditApp: string;
    AuditDate: Date;
    AuditId: number;
    Changes: TransactionRowHistoryChangesDTO[];
    ModifiedBy: number;
    ModifiedByUserFullName: string;
}

export interface TransactionRowHistoryChangesDTO {
    Name: string;
    NewValue: string;
    OldValue: string;
    PropertyName: string;
}

export interface TransactionRowsExtendedInfoDTO {
    InformationName: string;
    ContentCount: number;
    RowsCount: number;
}

export interface TransactionRowsSplitRowDTO {
    CustomCostObjectiveId: number;
    DimensionId: number;
    SumMoney: string;
}

export interface TransactionRowsSplitDTO {
    AccountDistributionItem: TransactionRowDTO;
    SplitBy?: CustomCostObjectiveFullDTO | {};
    SplitRows: TransactionRowsSplitRowDTO[];
}

export enum TransactionRowsImportType {
    FromInvoiceRows,
    FromAutotransactions,
    FromFile,
}

export enum TransactionRowsAutoTransactionsType {
    Existing,
    Template,
}

export enum TransactionRowsExportType {
    TabSeparated,
    Native,
    WithFormulas,
}

export interface RoleDTO {
    RoleDescription: string;
    RoleName: string;
    Id: number;
    RoleType: RoleType;
    UsersWithRole: number;
    TotalUsers: number;
    PredefinedId: number;
    IsNew: boolean;
}

export enum RoleType {
    Predefined,
    Custom,
}

export interface InvoiceDTO {
    AccountingDate: Date;
    Beneficiary: string;
    BuyerDepId: string;
    canConfirmCurrentTaskProp: boolean;
    canConfirmInvoiceProp: boolean;
    CanEditInvoice: boolean;
    CompanyGuid?: string;
    ContactName: string;
    ContractNumber: string;
    Currency: string;
    CurrencyRateDate?: Date;
    DashboardStatus: number;
    DeleteTransactionRowsDuringSave: boolean;
    Description: string;
    DefaultCurrencyRate?: number;
    displayAddWorkflowCompleterProp: boolean;
    DocumentFiles: FileDTO[];
    Company: CompanyDTO;
    DocumentType: string;
    ErpId: string;
    FileName: string;
    FileUrl: string;
    HubId: string;
    HubType: number;
    Id: number;
    ImportedDate: Date;
    InvoiceCustomization: InvoiceCustomizationDTO[];
    InvoiceDate: Date;
    InvoicePaidDateTime?: Date;
    InvoiceType: InvoiceTypeDTO;
    InvoiceTypeId?: number;
    IsCredit: boolean;
    IsDuplicate: boolean;
    IsExportAfterApprovalEnabled: boolean;
    isLastTask: boolean;
    IsNew: boolean;
    NextManagerName: string;
    Number: string;
    DueDate: Date;
    PayToAccount: string;
    PaymentMethod: PaymentMethodDTO;
    PaymentMethodId?: number;
    PurchaseOrder: string;
    ReferenceNumber: string;
    SupplierAddress: string;
    SupplierCode: string;
    Supplier: SupplierDTO;
    SupplierId: number;
    SupplierEmail: string;
    SupplierName: string;
    SupplierRegCode: string;
    SupplierRepresentative: string;
    SupplierVatCodeId: number;
    SlovakianSymbols: SlovakianSymbolsDTO;
    Status: number;
    SumWithoutVat: number;
    TotalAmountWithVat: number;
    TransactionRowsTotalSumRemaining: number;
    Vat: number;
    VatCodeId: number;
    Workflow: WorkflowDTO;
    WorkflowId: number;
    InvoiceAccountingRowsTotal?: number;
    UniqId?: string;
    InvoiceRowsPrice?: number;
    InvoiceRowsTotal?: number;
    InvoiceRowsVAT?: number;
    InvoiceRowsCount?: number;
    TransactionRowsPrice?: number;
    TransactionRowsTotal?: number;
    TransactionRowsVAT?: number;
}

export interface CustomCostObjectiveHistoryItemsItemDTO {
    Name: string;
    Code: string;
    Id: number;
    IsNew: boolean;
}

export interface CustomCostObjectiveHistoryItemDTO {
    Id: number;
    Name: string;
    Type: number;
    Code: string;
    IsNew: boolean;
    Items: CustomCostObjectiveHistoryItemsItemDTO[];
}

export interface CustomCostObjectiveHistoryDTO {
    Version: number;
    Items: CustomCostObjectiveHistoryItemDTO[];
}

export interface VatCodeHistoryItemDTO {
    Code: string;
    VatRate: number;
    Name: string;
    VatCodeType: string;
    Id: number;
    IsNew: boolean;
}

export interface VatCodesHistoryDTO {
    Version: number;
    Items: VatCodeHistoryItemDTO[];
}

export interface AccountsHistoryItemDTO {
    Code: string;
    Number: string;
    Id: number;
    IsNew: boolean;
}

export interface AccountsHistoryDTO {
    Version: number;
    Items: AccountsHistoryItemDTO[];
}

export interface InvoiceDataResponseDTO {
    CurrentGroupMember: GroupMemberDTO;
    LinkedPurchaseOrdersCount?: number;
    Invoice: InvoiceDTO;
    InvoiceAccountingRowsTotal: number;
    InvoiceRowsCount: number;
    InvoiceRowsPrice: number;
    InvoiceRowsTotal: number;
    InvoiceRowsVAT: number;
}

export interface InvoiceHeaderHistoryDTO {
    AuditDate: Date;
    ModifiedByName: string;
    PurchaseOrderNumber?: string;
    Change: InvoiceHeaderHistoryChangesDTO;
}

export interface InvoiceHeaderHistoryChangesDTO {
    NewValue: string;
    OldValue: string;
    Name: string;
    PropertyName?: string;
}

export interface AutoTransactionRowDTO {
    AutoTransactionId: number;
    RowSearchString: string;
    Comment: string;
    OrderNo: number;
    CombinationOption: number;
    CombineField: AutomationStepCombineField;
    CombineMatchType: AutoTransactionMatchType;
    VatRates: AutoTransactionUiVatRate[];
    Id: number;
    IsNew: boolean;
}

export enum MetaField {
    None,
    Id,
    Name,
}

export interface AutoTransactionMeta {
    Field: MetaField;
    Value: AutomationStepCombineField;
}

export enum AutomationStepCombineField {
    Description = 'Description',
    SerialNumber = 'SerialNumber',
    SellerProductId = 'SellerProductId',
    BuyerProductId = 'BuyerProductId',
    TaricCode = 'TaricCode',
    CustomerRef = 'CustomerRef',
    EAN = 'EAN',
    PartnerCode = 'PartnerCode',
    BusinessCode = 'BusinessCode',
    SourceCode = 'SourceCode',
    CashFlowCode = 'CashFlowCode',
    ClassificatorCode = 'ClassificatorCode',
    GroupId = 'GroupId',
}

export enum AutoTransactionTriggerType {
    'COMPANY' = 1,
    'REFERENCE_NO' = 2,
}

export interface AutoTransactionTriggerDTO {
    TriggerType: AutoTransactionTriggerType;
    MatchType: AutoTransactionMatchType;
}

export interface AssigneeDTO {
    Id: number;
}

export interface SelectedOption {
    Assignee: AssigneeDTO;
    Value: WorkflowAssignmentType;
}

export enum WorkflowAssignmentType {
    Person = 1,
    Workflow = 2,
}

export interface WorkflowAssignment {
    SelectedOption: SelectedOption;
}

export interface AutoTransactionDTO {
    RuleName: string;
    RuleDescription: string;
    SupplierId: number;
    Supplier: SupplierDTO;
    ReferenceNumber: number | string;
    IsActive: boolean;
    LastRun: Date;
    LastInvoiceId: number;
    LastInvoice: InvoiceDTO;
    ModifiedBy: number;
    StopProcessing: boolean;
    WorkflowTemplateId: number;
    WorkflowTemplate: WorkflowTemplateDTO;
    ReferenceNumberMatchType: number;
    CompanyMatchType: number;
    RecreateTransactionRows: boolean;
    AutoTransactionsRows: AutoTransactionsRowsListItemDTO[];
    AutoTransactionsTriggers: AutoTransactionsTriggerDTO[];
    Id: number;
    IsNew: boolean;
}

export interface AdditionalFieldDTO {
    CustomCostObjectiveId: number;
    AccountId: number;
    Id: number;
    IsNew: boolean;
}

export interface AccountDTO {
    AdditionalFields: AdditionalFieldDTO[];
    AssignedCurrentToUser: boolean;
    AutoTransactionCountInUse: number;
    Code: string;
    EndDate: string;
    Id: number;
    InvoiceCountInUse: number;
    IsNew: boolean;
    Description: string;
    StartDate: string;
}

export interface CustomCostObjectiveDTO {
    Code: string;
    Description: string;
    IsMandatory: boolean;
    IsVisible: boolean;
    OrderNo: number;
    Id: number;
    IsNew: boolean;
    InvoiceCountInUse: number;
    AutoTransactionCountInUse: number;
    StartDate: Date;
    EndDate: Date;
}

export interface CustomCostObjectiveFullDTO {
    Description: string;
    Code: string;
    Dimensions: DimensionDTO[];
    DimensionsCount?: number;
    IsMandatory: boolean;
    IsVisible: boolean;
    Id: number;
    IsNew: boolean;
    InvoiceCountInUse: number;
    AutoTransactionCountInUse: number;
    OrderNo?: number;
    StartDate?: Date;
    EndDate?: Date;
    SetActiveForUsers?: boolean;
    Type?: string;
}

export interface CustomCostObjectiveMediumDTO {
    Name: string;
    Type: number;
    Code: string;
    DimensionsCount: 0;
    IsMandatory: boolean;
    IsVisible: boolean;
    Id: number;
    IsNew: boolean;
    InvoiceCountInUse: number;
    AutoTransactionCountInUse: number;
    OrderNo?: number;
}

export interface DimensionDTO {
    AssignedCurrentToUser: boolean;
    AutoTransactionCountInUse: number;
    Code: string;
    EndDate: string;
    CustomCostObjectiveId: number;
    CustomCostObjective?: CustomCostObjectiveFullDTO;
    Id: number;
    DimensionId?: number;
    InvoiceCountInUse: number;
    IsNew: boolean;
    Description: string;
    StartDate: string;
}

export interface TransactionRowDimensionDTO {
    IsMandatory: boolean;
    CustomCostObjective: CustomCostObjectiveFullDTO;
    CustomCostObjectiveId: number;
    IsVisible: boolean;
    DimensionId: number;
    Dimension?: DimensionDTO;
    Id?: number;
    TransactionRowId?: number;
}

export interface Error {
    ObjectId: string;
    ErrorCode: number;
    ErrorMessage: string;
    StackTrace: string;
}

export interface Response<T> {
    Success: boolean;
    Message: string;
    Errors: Error[];
    ResultObject: T;
}

export interface ApiResultOf<T> {
    Success: boolean;
    Messages: string[];
    Result: T;
}

export interface BooleanResponse {
    Success: boolean;
    Message: string;
}

export interface ApiResultOfResponse extends ApiResultOf<Response<any>> {}

export interface CostObjectiveItemsCountForUserDTO {
    Count: number;
    Id: number;
    Name: string;
    ObjectType: CostObjectiveType;
    OrderNo: number;
}

/**
 * The automation trigger party collection
 */
export enum ExtensionType {
    InvoiceInformation = 1,
    SellerParty = 2,
    BuyerParty = 3,
    RecipientParty = 4,
    DeliveryParty = 5,
    PayerParty = 6,
    FactorParty = 7,
    InvoiceSumGroup = 8,
    InvoiceRows = 9,
    PaymentInformation = 10,
}

/**
 * The automation trigger field
 */
export enum ExtensionField {
    GLN = 1,
    UniqueCode = 2,
    DepartmentId = 3,
    ContactPerson = 4,
    ExtensionId = 5,
    BeneficiaryName = 6,
    Company = 7,
    ContentCode = 8,
    ContentText = 9,
    ContractNumber = 10,
    Description = 11,
    DocumentName = 12,
    ExtensionContent = 13,
    ExtensionName = 14,
    FactorContractNumber = 15,
    GrandTotal = 16,
    NETAmount = 17,
    InvoiceNumber = 18,
    ReferenceNumber = 19,
    InvoiceType = 20,
}

export enum TriggerType {
    CompanyName = 1,
    ReferenceNumber = 2,
    MetaExtension = 3,
}

export interface AutoTransactionTriggerMeta {
    ExtensionType: ExtensionType;
    ExtensionField: ExtensionField;
    Value: string;
}

export interface Trigger {
    MatchType: AutoTransactionMatchType;
    ExtensionType: ExtensionType;
    ExtensionField: ExtensionField;
    Value: string;
    DisplayValue: string;
}

export interface AutoTransactionUiRowSearchConditionDTO {
    CombineField: AutomationStepCombineField;
    CombineMatchType: AutoTransactionMatchType;
    RowSearchString: string;
    OrderNo: number;
}

export interface AutoTransactionUiRowsObjectDTO {
    AutoTransactionId: number;
    Comment: string;
    OrderNo: number;
    CombinationOption: number;
    Conditions: AutoTransactionUiRowSearchConditionDTO[];
    VatRates: AutoTransactionUiVatRate[];
    Id: number;
    IsNew: boolean;
}

export interface AutoTransactionInvoiceCustomFieldDTO {
    AutoTransactionId: number;
    CustomizationId: number;
    CustomizationCode: string;
    CustomizationType: InvoiceFieldType;
    Value: string;
    ModifiedBy?: number;
}

export interface AutoTransactionUiObjectDTO {
    RuleName: string;
    RuleDescription: string;
    Beneficiary: string;
    SupplierId: number;
    Supplier: SupplierDTO;
    ReferenceNumber: number;
    IsActive: boolean;
    LastRun: Date;
    LastInvoiceId: number;
    LastInvoice: InvoiceDTO;
    ModifiedBy: number;
    StopProcessing: boolean;
    RecreateTransactionRows: boolean;
    AutoTransactionsRows: AutoTransactionUiRowsObjectDTO[];
    Triggers: Trigger[];
    WorkflowAssignments: WorkflowAssignment[];
    Id: number;
    IsNew: boolean;
    AutoTransactionsCustomFields: AutoTransactionInvoiceCustomFieldDTO[];
}

export interface PagedListContainerOf<T> {
    Items: T[];
    Skip: number;
    Take: number;
    TotalCount: number;
    HasCount: boolean;
}

export interface AutoTransactionRuleSimpleDTO {
    Id: number;
    Name: string;
    RecreateTransactionRows: boolean;
}

export interface PagedListContainerOfAutoTransactionsDTO extends PagedListContainerOf<AutoTransactionDTO> {}

export type DimensionItemUnion = AccountDTO | VatCodeDTO | DimensionDTO;

export interface UserBOCompany extends UserCompany {
    RegistrationCode: string;
    UIAddress: string;
    WaitingInvoiceCount?: number;
    Status: number;
    DbName: string;
    DbServerIp: string;
    DbType: number;
    DbSchema: string;
}

export interface SystemConfigurationDTO {
    Name: string;
    Value: string;
    Id: number;
    IsNew: boolean;
    ModifiedBy?: string;
    Type?: string;
}

export interface AutoTransactionsRowsDimensionDTO {
    AutoTransactionRowId: number;
    DimensionId: number;
    Id: number;
    IsNew: boolean;
}

export interface AutoTransactionsRowsListItemDTO {
    AutoTransactionId: number;
    RowSearchString: string;
    RowSearchConditions: string;
    Percentage: number;
    AllocationDescription: string;
    AllocationComment: string;
    AccountId: number;
    VatCodeId: number;
    VatCodeRate: number;
    Comment: string;
    OrderNo: number;
    AutoTransactionsRowsDimensions: AutoTransactionsRowsDimensionDTO[];
    Id: number;
    IsNew: boolean;
}

export interface AutoTransactionsTriggerDTO extends Trigger {
    Id: number;
    AutoTransactionId: number;
}

export enum PaymentMethodInvoiceType {
    None = 0,
    PurchaseInvoice = 1 << 0,
    Expense = 1 << 1,
    All = ~(~0 << 2),
}

export interface PaymentMethodDTO {
    Id: number;
    IsNew: boolean;
    Code: string;
    Description: string;
    InvoiceTypes: PaymentMethodInvoiceType;
    IsDefault: boolean;
    ModifiedBy: number;
    InvoiceCountInUse: number;
}

export interface ProductItemDTO {
    Id: number;
    Code: string;
    Description: string;
    IsUsed: boolean;
    FullName: string;
    SellerCodes?: ProductItemSellerCodesDTO[];
}

export interface ProductItemSellerCodesDTO {
    Id: number;
    BuyerCode: string;
    SellerCode: string;
}

export interface RelatedDimensionsDTO {
    SourceCode: string;
    SourceValue: string;
    DestinationCode: string;
    DestinationValue: string;
    Id: number;
    IsNew: boolean;
}

export interface DocumentTypeDTO {
    Code: string;
    Type: string;
    Id: number;
}

export interface Version {
    Major: number;
    Minor: number;
    Build: number;
    Revision: number;
    MajorRevision: number;
    MinorRevision: number;
}

export enum HttpStatusCode {
    Continue = 100,
    SwitchingProtocols = 101,
    OK = 200,
    Created = 201,
    Accepted = 202,
    NonAuthoritativeInformation = 203,
    NoContent = 204,
    ResetContent = 205,
    PartialContent = 206,
    MultipleChoices = 300,
    Ambiguous = 300,
    MovedPermanently = 301,
    Moved = 301,
    Found = 302,
    Redirect = 302,
    SeeOther = 303,
    RedirectMethod = 303,
    NotModified = 304,
    UseProxy = 305,
    Unused = 306,
    TemporaryRedirect = 307,
    RedirectKeepVerb = 307,
    BadRequest = 400,
    Unauthorized = 401,
    PaymentRequired = 402,
    Forbidden = 403,
    NotFound = 404,
    MethodNotAllowed = 405,
    NotAcceptable = 406,
    ProxyAuthenticationRequired = 407,
    RequestTimeout = 408,
    Conflict = 409,
    Gone = 410,
    LengthRequired = 411,
    PreconditionFailed = 412,
    RequestEntityTooLarge = 413,
    RequestUriTooLong = 414,
    UnsupportedMediaType = 415,
    RequestedRangeNotSatisfiable = 416,
    ExpectationFailed = 417,
    UpgradeRequired = 426,
    InternalServerError = 500,
    NotImplemented = 501,
    BadGateway = 502,
    ServiceUnavailable = 503,
    GatewayTimeout = 504,
    HttpVersionNotSupported = 505,
}

export interface HttpRequestMessage {
    Version: Version;
    Content: {}[];
    Method: string;
    RequestUri: URL;
    Headers: {}[];
    Properties: {}[];
}

export interface HttpResponseMessage {
    Version: Version;
    Content: {}[];
    StatusCode: HttpStatusCode;
    ReasonPhrase: string;
    Headers: {}[];
    RequestMessage: HttpRequestMessage;
    IsSuccessStatusCode: boolean;
}

/**
 * Request parameters type for getting PO for a particular Invoice (invoice details header)
 * POST /PurchaseOrders/GetPurchaseOrdersForInvoice
 * REQUEST GetPurchaseOrdersForInvoiceRequest
 * RESPONSE PagedListContainer<PurchaseOrdersDTO>
 */
export interface GetPurchaseOrdersForInvoiceParams {
    paging: PagingOptions;
    invoiceId: number;
    supplierId: number;
    searchStr: string;
}

/**
 * BEGIN new API types for performance optimization task
 */

/**
 * Currently the main function to get the data for "invoice list" views is api/Invoice/GetInvoicesSmall
 * (the dashboard, the invoice registry and the invoice confirmation view slider at the top)
 * which returns a list of basically the full invoice data, which is a tremendous amount of data.
 * This data includes multiple instances of full CompanyDataDTO much more.
 */

/**
 * POST api/Invoice/GetSliderInvoicesMin
 * REQUEST BaseSearch
 * RESPONSE PagedListContainer<InvoiceSliderMinDTO>
 */
// minimal invoice data for Invoice Slider component
export interface InvoiceSliderMinDTO {
    Id: number;
    SupplierName: string;
    TotalAmountWithVat: number;
    Currency: string;
    Number: string;
    InvoiceDate: Date;
}

/**
 * POST api/Invoice/GetDashboardInvoicesMin
 * REQUEST InvoiceSearchOptions
 * RESPONSE PagedListContainer<InvoiceDashboardMinDTO>
 */
// minimal invoice data for dashboard invoice lists
export interface InvoiceDashboardMinDTO extends InvoiceSliderMinDTO {
    Status: number;
    SupplierCode: string;
    IsCredit: boolean;
    IsDuplicate: boolean;
    Description: string;
    PaymentDate: Date;
}

/**
 * POST api/Invoice/GetRegistryInvoicesMin
 * REQUEST BaseSearch
 * RESPONSE PagedListContainer<InvoiceRegistryMinDTO>
 */
// minimal invoice data for Invoice register view
export interface InvoiceRegistryMinDTO extends InvoiceDashboardMinDTO {
    AccountingDate: Date;
    CreatedDate: Date;
    CompanyGuid: number;
    DueDate: Date;
    CurrentWorkflowUsers: string[];
}

export interface DashboardGroupMemberMinDTO {
    UserFullName: string;
    SubstituterUserFullName: string;
}

export interface WorkflowTaskMinDTO {
    Completed: Date;
    GroupMember: DashboardGroupMemberMinDTO;
    StatusLookupId: number;
    Comment: string;
    ProcessedById: number;
    ProcessedByName: string;
}

export interface WorkflowMinDTO {
    Tasks: WorkflowTaskMinDTO[];
}

export interface FileMinDTO {
    Id: number;
    FileName: string;
    IsPdf: boolean;
}

/**
 * GET api/Invoice/GetDashboardInvoiceExt?id={INVOICE_ID}
 * RESPONSE ApiResultOf<InvoiceDashboardExtDTO>
 */
// extended Invoice data for Approvers Dashboard expanded data
export interface InvoiceDashboardExtDTO extends InvoiceDashboardMinDTO {
    DocumentFiles: Array<FileMinDTO>; // only thing used is the fileName prop
    Workflow: WorkflowMinDTO;
}

/**
 * GET api/Invoice/GetInvoiceDataMin?id={INVOICE_ID}
 * REQUEST BaseSearch
 * RESPONSE ApiResultOf<InvoiceConfirmationMinDTO>
 */
// Minimal invoice data for collapsed Invoice header in InvoiceConfirmationView
export interface InvoiceConfirmationMinDTO extends InvoiceRegistryMinDTO {
    ErpId: string;
    ReferenceNumber: string;
    Vat: number;
    SumWithoutVat: number;
}

/**
 * GET api/Invoice/GetInvoiceDataExt?id={INVOICE_ID}
 * REQUEST BaseSearch
 * RESPONSE ApiResultOf<InvoiceConfirmationExtDTO>
 */
// extended invoice data for expanded Invoice Header in InvoiceConfirmationView
export interface InvoiceConfirmationExtDTO extends InvoiceConfirmationMinDTO {
    Supplier: {
        Id: number;
        RegistrationCode: string;
        Address: string;
        Email: string;
    };
    Beneficiary: string;
    ContractNumber: string;
    PayToAccount: string;
    Company: {
        Id: number;
        Name: string;
        Address: string;
    };
    ContactName: string;
    PurchaseOrder: string;
    DocumentType: string;
    InvoiceCustomization: Array<{
        Id: number;
        CustomizationId: number;
        Customization: {
            Id: number;
            Name: string;
            FieldType: CostObjectiveType;
            IsMandatory: boolean;
        };
        Value: number;
    }>;
    InvoicePaidDate: Date;
}

/**
 * POST api/PurchaseOrder/GetDashboardPurchaseOrdersMin
 * REQUEST BaseSearch
 * RESPONSE PagedListContainer<PurchaseOrderDashboardMinDTO>
 */
// minimal purchase order data for dashboard list
export interface PurchaseOrderDashboardMinDTO {
    SupplierName: string;
    OrderNumber: string;
    DateCreated: Date;
    Currency: string;
    Total: number;
}

/**
 * POST api/PurchaseOrder/GetPurchaseOrdersMin
 * REQUEST BaseSearch
 * RESPONSE PagedListContainer<PurchaseOrdersListMinDTO>
 */
// minimal purchase order data for purchase orders list view
export interface PurchaseOrdersListMinDTO extends PurchaseOrderDashboardMinDTO {
    Description: string;
    OrderType: number;
    Status: number;
    CurrentlyAssignedUsers: PurchaseOrderAssignedUser[];
}

/**
 * POST api/Invoice/GetInvoiceRowsMin?id={INVOICE_ID}
 * REQUEST BaseSearch
 * RESPONSE PagedListContainer<InvoiceRowMinDTO>
 */
// minimal invoice row data for collapsed invoice row view
export interface InvoiceRowMinDTO {
    Id: number;
    Description: string;
    ItemDetailInfo: Array<{
        ItemAmount: number;
        ItemPrice: number;
        ItemUnit: string;
    }>;
    Total: number;
    Currency: string;
    Vat: number;
    VatRate: number;
    ItemReserve: Array<{
        InformationName: string;
        ExtensionId: string;
        InformationContent: string;
    }>;
}

/**
 * GET api/Invoice/GetInvoiceRowsExt?id={INVOICE_ID}&row={INVOICE_ROW_ID}
 * RESPONSE ApiResultOf<InvoiceRowExtDTO>
 */
// extended invoice row data for expanded invoice row view
export interface InvoiceRowExtDTO extends InvoiceRowMinDTO {
    SerialNumber: string;
    SellerProductId: string;
    BuyerProductName: string;
    BuyerProductId: string;
    GroupId: string;
    TaricCode: string;
    CustomerRef: string;
    EAN: string;
}

/**
 * POST api/FieldTemplate/GetCompanyFieldTemplatesListMin
 * PARAMS {
 *     SearchString: string;
 *     ExcludeIds: number[];
 *     IncludeIds: number[];
 * }
 * RESPONSE ApiResultOf<Array<WorkflowMinDTO>>
 */
// getCompanyTemplates sama moodi nagu GetApproversList plz
export interface WorkflowMinDTO {
    Id: number;
    Name: string;
}

/**
 * POST api/Invoice/UpdateAccount/{TRANSACTION_ROW_ID}?accountID={ACCOUNT_ID}
 * PARAMS CustomCostObjectiveMinDTO
 * RESPONSE ApiResultOf<TransactionRowDTO>
 */
// minimal custom cost objective data for updating cost transaction rows custom cost objectives
// InvoiceController.cs #600 public List<DimensionDto> UpdateCustomFieldWithRelations(DimensionDTO dimDto, int rowId, int coId)
export interface CustomCostObjectiveMinDTO {
    Id: number;
    FieldValue: number; // FieldTemplateItem.Id
    FieldTemplate: {
        Id: number;
        Code: string;
    }; // FieldTemplateDTO; // ainult Code
    FieldTemplateItem: {
        Id: number;
        Code: string;
        FieldTemplateId: number;
    }; // FieldTemplateItemDTO; // ainult Code
    InvoiceId: number;
}

/**
 * POST api/AutoTransaction/GetListMin
 * REQUEST BaseSearch
 * RESPONSE PagedListContainer<AutoTransactionListMinDTO>
 */
// minimal auto transaction data for auto transactions list view
export interface AutoTransactionListMinDTO {
    Id: number;
    IsActive: boolean;
    RuleName: string;
    SupplierName: string;
    LastRun: Date;
    LastInvoice: {
        Id: number;
        Number: string;
    };
    Triggers: [
        {
            ExtensionField: ExtensionField;
            MatchType: AutoTransactionMatchType;
            Value: string;
        },
    ];
}

export interface HistoryDTO {
    GroupMemberId: number;
    InvoiceId: number;
    ActionId: HistoryAction;
    AdditionalInfo: string;
    LogDate: string;
    Parameter: string;
    Id: number;
    IsNew: boolean;
}

export enum HistoryAction {
    InsertedInvoice,
    ImportedInvoice,
    DeletedInvoice,
    RejectInvoice,
    DuplicateInvoice,
    ExportedInvoice,
    ImportedTransactionRows,
    CopyInvoice,
    DisableExport,
    EnableExport,
    AutotransactionApplied,
    ResendToExport,
    DynamicWorkflowTemplateApplied,
    DeletedUserFromWorkflowDynamically,
    AssignedInvoice,
    ApprovedInvoice,
    ReopenedInvoice,
    TransferedToOtherCompany,
    RestoredInvoice,
    FileDigitizedInMobileApp,
    SetAsNewInvoice,
}

export interface PurchaseOrderHistoryDTO {
    GroupMemberId: number;
    GroupMemberName: string;
    PurchaseOrderId: number;
    ActionId: PurchaseOrderHistoryAction;
    AdditionalInfo: string;
    LogDate: Date;
}

export enum PurchaseOrderHistoryAction {
    Unknown,
    CreatedManually,
    Cloned,
    ImportV3Api,
    FulfilmentChange,
}

export interface ReplaceTasksInWorkflow {
    Id: number;
    InvoiceId: number;
    Tasks: TaskDTO[];
    ApproversPerSteps: ApproverPerStep[];
}

export interface ApproverDTO {
    Id: number;
    Name: string;
    IsTemplate: boolean;
    MemberRoles: MemberRoleDTO[];
}

export interface SessionDTO {
    LoginIP: string;
    DeviceName: string;
    AppVersion: string;
    OSName: string;
    BrowserName: string;
    BrowserVersion: string;
    Id: number;
    IsCurrent: boolean;
    Location: LocationDTO;
}

export interface LocationDTO {
    IpAddress: string;
    Country: string;
    CountryCode: string;
    PhoneCode: string;
    OfficialLanguageCode: string;
    Region: string;
    Currency: string;
    FirstDay: string;
    StandardVatRate: string;
    IsEuCountry: boolean;
    Status: string;
}

export interface CompanyAccessCheckDTO {
    BackOfficeCompanyGuid: string;
}

export interface CompanyAccessCheckResponseDTO {
    CompanyAccessStatus: CompanyAccessStatus;
    CompanyAccessStatusText: string;
}

export enum CompanyAccessStatus {
    COMPANY_USER_OK = 0,
    COMPANY_INACTIVE = 1,
    USER_INACTIVE = 2,
    COMPANY_NOT_FOUND = 3,
    USER_NOT_FOUND = 4,
}

export enum AttachmentType {
    Invoice = 1,
    PurchaseOrder = 2,
}

export interface AttachmentFilesCountResponseDTO {
    AttachmentsCount: number;
    TotalSize: number;
}

export interface UserCreationDTO {
    FirstName: string;
    LastName: string;
    Country: string;
    PersonalCode: string;
    Email: string;
    MembershipStart: Date;
    MembershipEnd: Date;
    MemberRoles: MemberRoleDTO[];
    MonetaryCurrency?: string;
    MonetaryLimit?: number;
    IsActive?: boolean;
    UserGuid?: string;
}

export interface UserCreationResultDTO {
    UserId: number;
    UserGuid: string;
    Success: boolean;
    ExistingUserAddedToCompany?: boolean;
    Errors: string[];
}
