import { CombinationOptionType } from '../../../../../services/types/ApiTypes';
import CombineByMethodsDropdown from './CombineByMethodsDropdown';
import { FastField, FormikProps } from 'formik';
import { TextInputField } from '../../../../../components/TextInput/TextInput';
import { addDataId } from '../../../../../common/utils/dataId';
import { AutomationStepFields, AutoTransactionsAddViewFields, ConditionFields } from '../../../autoTransactionAddViewFields';
import { TFunction } from 'i18next';
import { stepHasRowSearchStringInput } from './Conditions';
import React from 'react';
import { AutomationStepCombineFieldItem } from '../../../AutoTransactionsAddViewHelper';

export interface CombineValueInputProps {
    allCombineFields: AutomationStepCombineFieldItem[];
    automationStep: AutomationStepFields;
    condition: ConditionFields;
    fieldNamePrefix: string;
    formik: FormikProps<AutoTransactionsAddViewFields>;
    t: TFunction;
}

export const CombineValueInput: React.FC<CombineValueInputProps> = ({ allCombineFields, automationStep, condition, fieldNamePrefix, formik, t }) => {
    switch (automationStep.combinationOption) {
        case CombinationOptionType.ApplyToAllWhere:
        case CombinationOptionType.CombineSearch:
            return stepHasRowSearchStringInput(condition) ? (
                <FastField
                    key={`${fieldNamePrefix}.conditions[${condition.orderNo - 1}].rowSearchString`}
                    wrapperClass={'automation-step__item'}
                    component={TextInputField}
                    name={`${fieldNamePrefix}.conditions[${condition.orderNo - 1}].rowSearchString`}
                    autosize={true}
                    placeholder={t('component.AutoTransaction.PlaceHolder.ClickToSet')}
                    onFocusPlaceholder={t('component.AutoTransaction.PlaceHolder.TypeToSet')}
                    onlyChangeOnBlur={true}
                    dataId={addDataId(`${fieldNamePrefix}.conditions[${condition.orderNo - 1}]`, '.rowSearchString')}
                />
            ) : null;
        case CombinationOptionType.CombineBy:
            return (
                <CombineByMethodsDropdown
                    key={`${fieldNamePrefix}.conditions[${condition.orderNo - 1}].combineBySelection`}
                    allCombineFields={allCombineFields}
                    condition={condition}
                    t={t}
                    fieldNamePrefix={`${fieldNamePrefix}.conditions[${condition.orderNo - 1}]`}
                    formik={formik}
                    callback={(selectedValue: AutomationStepCombineFieldItem) => {
                        formik.setFieldValue(`${fieldNamePrefix}.conditions[${condition.orderNo - 1}]`, {
                            ...condition,
                            rowSearchString: selectedValue.value,
                            combineBySelection: {
                                text: selectedValue.value,
                                value: selectedValue,
                            },
                        });
                    }}
                />
            );
        case CombinationOptionType.CombineAll:
        case CombinationOptionType.ApplyAll:
        default:
            return null;
    }
};

export default React.memo(CombineValueInput);
