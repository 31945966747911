import React, { useEffect, useState } from 'react';
import { Form, Formik, FormikActions, FormikErrors, FormikProps, yupToFormErrors } from 'formik';
import { TFunction } from 'i18next';
import * as Yup from 'yup';
import { debounce, isEmpty, isEqual } from 'lodash-es';

import FormikField from '../../../../common/utils/FormikField';
import { formatDate, formatMoney } from '../../../../common/utils/formatters';
import { Button, ButtonType, ButtonIconPlacement } from '../../../../components/Buttons/Button';
import { TextInput, TextInputField, TextInputType } from '../../../../components/TextInput/TextInput';
import { WithTranslateFormErrors } from '../../../../components/WithTranslateFormErrors/WithTranslateFormErrors';
import { SupplierDTO, Currency, PurchaseOrdersDTO, PurchaseOrderType, PurchaseOrderStatus } from '../../../../services/types/ApiTypes';
import { createDataId } from '../../../../common/utils/dataId';
import { ICONS } from '../../../../components/Icon/Icon';
import api from '../../../../services/ApiServices';
import { TypeaheadAsync, TypeaheadAsyncField } from '../../../../components/Typeahead/TypeaheadAsync';
import { Typeahead, TypeaheadItem } from '../../../../components/Typeahead/Typeahead';
import { LoadableData } from '../../../../common/utils/LoadableData';
import { getCurrencyName } from '../../../../common/user/userUtils';
import fieldNames, { fieldNamesSource, PurchaseOrdersAddViewFields } from '../../purchaseOrderAddViewFields';
import { filterCurrenciesByName } from '../../../invoice-details/components/invoice-header/utils';
import AddNewSupplierButton from '../../../invoice-details/components/invoice-header/components/AddSupplierButton';
import { orderTypesList, validatePurchaseOrderNumberField } from '../../PurchaseOrdersAddViewHelper';

export const dataId = 'purchase-order-header.edit';

interface Props {
    purchaseOrder: PurchaseOrdersDTO;
    cancelEditting: (state: boolean) => void;
    t: TFunction;
    showAddOrEditSupplierModal: (s: SupplierDTO) => void;
    addSupplierLoadable: LoadableData<SupplierDTO, SupplierDTO>;
    updateSupplierLoadable: LoadableData<SupplierDTO, SupplierDTO>;
    updatePurchaseOrder: (values: PurchaseOrdersAddViewFields) => void;
}

const HeaderEditMode = ({ purchaseOrder, cancelEditting, t, showAddOrEditSupplierModal, addSupplierLoadable, updateSupplierLoadable, updatePurchaseOrder }: Props) => {
    const [currencyList, setCurrencyList] = useState<Currency[]>(null);
    const [selectedType, setSelectedType] = useState<TypeaheadItem<number>>({
        text: purchaseOrder?.OrderType ? PurchaseOrderType[purchaseOrder.OrderType].toLocaleUpperCase() : null,
        value: purchaseOrder?.OrderType,
    });
    const [purchaseOrderFormValues, setPurchaseOrderFormValues] = useState<PurchaseOrdersAddViewFields>({} as PurchaseOrdersAddViewFields);
    const [isFormDirty, setIsFormDirty] = useState<boolean>(false);

    const isOrderNumberFieldDisabled = ![PurchaseOrderStatus.New, PurchaseOrderStatus.Assigned].includes(purchaseOrder?.OrderStatus);

    useEffect(() => {
        if (purchaseOrder) {
            const newPurchaseOrderValues: PurchaseOrdersAddViewFields = { ...fieldNamesSource };
            newPurchaseOrderValues.Description = purchaseOrder?.Description;
            newPurchaseOrderValues.Issued = purchaseOrder?.DateCreated ? new Date(purchaseOrder.DateCreated) : null;
            newPurchaseOrderValues.PoNumber = purchaseOrder?.OrderNumber;
            newPurchaseOrderValues.Type = purchaseOrder?.OrderType;
            newPurchaseOrderValues.Id = purchaseOrder?.Id;
            newPurchaseOrderValues.Rows = purchaseOrder?.PurchaseOrdersRows;
            newPurchaseOrderValues.Currency = {
                text: purchaseOrder?.Currency ? `${purchaseOrder.Currency} - ${getCurrencyName(purchaseOrder.Currency)}` : null,
                value: purchaseOrder?.Currency || null,
            };
            newPurchaseOrderValues.CreatedBy = purchaseOrder?.CreatedByName;
            newPurchaseOrderValues.Supplier = purchaseOrder?.Supplier ? { text: purchaseOrder?.Supplier?.Name, value: purchaseOrder?.Supplier } : null;
            if (!isEqual(purchaseOrderFormValues, newPurchaseOrderValues) && isFormDirty) {
                // prevent showing warning message when a new invoice is loaded and user wants to navigates away before making changes
                setIsFormDirty(false);
            }
            setPurchaseOrderFormValues(newPurchaseOrderValues);
        }
    }, [purchaseOrder]);

    const validationSchema = (() => {
        return Yup.object<PurchaseOrdersAddViewFields>().shape({
            [fieldNames.PoNumber]: Yup.string()
                .ensure()
                .required(t('view.Accounting.MandatoryField', { fieldName: t('component.purchaseOrder.header.orderNumber') })),
            [fieldNames.Supplier]: Yup.object()
                .nullable(true)
                .required(t('view.Accounting.MandatoryField', { fieldName: t('view.PurchaseOrders.Supplier') })),
            [fieldNames.Description]: Yup.string()
                .ensure()
                .required(t('view.Accounting.MandatoryField', { fieldName: t('view.Accounting.Description') })),
        });
    })();

    const validationSchemaOnSubmit = Yup.object<PurchaseOrdersAddViewFields>().shape({
        [fieldNames.PoNumber]: Yup.string().test('unique-poNumber', t('PurchaseOrders.Error.OrderNumberAlreadyExists'), function(value: string) {
            if (!value || value.toLowerCase() === purchaseOrder?.OrderNumber?.toLowerCase()) {
                return true;
            }
            return new Promise((resolve) => {
                validatePurchaseOrderNumberField(value, resolve);
            });
        }),
    });

    const loadSupplier = (supplier: SupplierDTO) => {
        if (supplier) {
            setPurchaseOrderFormValues({ ...purchaseOrderFormValues, Supplier: { text: supplier.Name, value: supplier } });
            setIsFormDirty(true);
        }
    };

    const changeSupplier = (s: TypeaheadItem<SupplierDTO>, values: PurchaseOrdersAddViewFields) => {
        setPurchaseOrderFormValues({ ...values, Supplier: s });
        setIsFormDirty(true);
    };

    useEffect(() => {
        if (addSupplierLoadable?.loaded && addSupplierLoadable?.payload?.Id && !addSupplierLoadable?.payload?.IsNew && !isEmpty(purchaseOrderFormValues)) {
            loadSupplier(addSupplierLoadable.payload);
        }
    }, [addSupplierLoadable]);

    useEffect(() => {
        if (updateSupplierLoadable?.loaded && updateSupplierLoadable?.payload?.Id && !isEmpty(purchaseOrderFormValues)) {
            loadSupplier(updateSupplierLoadable.payload);
        }
    }, [updateSupplierLoadable]);

    const mapFormValuesToPurchaseOrderFields = (vals: PurchaseOrdersAddViewFields): PurchaseOrdersAddViewFields => ({
        ...vals,
        Currency: vals.Currency,
        Type: selectedType.value,
    });

    const updateOrder = (values: PurchaseOrdersAddViewFields) => updatePurchaseOrder(mapFormValuesToPurchaseOrderFields(values));

    const validationOnSubmit = (values: PurchaseOrdersAddViewFields, setErrors: (errors: FormikErrors<unknown>) => void) => {
        validationSchemaOnSubmit
            .validate(values, { abortEarly: false })
            .then(() => {
                setErrors({});
                updateOrder(values);
            })
            .catch((e) => {
                setErrors(yupToFormErrors(e));
            });
    };

    const validationOnSubmitDebounced = debounce(validationOnSubmit, 400);

    const handleSubmit = (values: PurchaseOrdersAddViewFields, { setErrors }: FormikActions<PurchaseOrdersAddViewFields>) => {
        validationOnSubmitDebounced(values, setErrors);
    };

    const getSuppliersByName = async (suppName?: string): Promise<SupplierDTO[]> => {
        if (suppName) {
            const response = await api.suppliers.getSuppliersByName(suppName);
            return response.data;
        }
        const response = await api.suppliers.getSuppliers();
        return response.data.Items;
    };

    const getCurrenciesByName = async (name: string): Promise<Currency[]> => {
        if (!currencyList?.length) {
            const { data } = await api.invoice.getCurrencyList();
            setCurrencyList(data);
            return filterCurrenciesByName(name, data);
        } else {
            return filterCurrenciesByName(name, currencyList);
        }
    };

    return (
        <Formik onSubmit={handleSubmit} initialValues={purchaseOrderFormValues} enableReinitialize={true} validationSchema={validationSchema} validateOnChange={true} validateOnBlur={true}>
            {(formik: FormikProps<PurchaseOrdersAddViewFields>) => (
                <WithTranslateFormErrors errors={formik.errors} setFieldTouched={formik.setFieldTouched} touched={formik.touched}>
                    <div className="purchase-order-header__actions--row">
                        <div className="purchase-order-header__actions purchase-order-header__actions--supplier">
                            <div className="text-button-wrapper">
                                <Button
                                    dataId={createDataId(dataId, 'button', 'add-new-supplier')}
                                    onClick={() => showAddOrEditSupplierModal(undefined)}
                                    buttonType={ButtonType.ICON_TEXT}
                                    icon={ICONS.ADD_24}
                                    iconPlacement={ButtonIconPlacement.LEFT}
                                >
                                    {t('views.company.detail.Add_Company')}
                                </Button>
                            </div>
                            <div className="text-button-wrapper">
                                <Button
                                    dataId={createDataId(dataId, 'editSupplierButton')}
                                    disabled={!purchaseOrderFormValues?.Supplier?.value}
                                    buttonType={ButtonType.ICON_TEXT}
                                    icon={ICONS.EDIT_24}
                                    onClick={() => showAddOrEditSupplierModal(purchaseOrderFormValues?.Supplier?.value)}
                                    iconPlacement={ButtonIconPlacement.LEFT}
                                >
                                    {t('component.basicInfo.editSupplier')}
                                </Button>
                            </div>
                        </div>
                        <div className="purchase-order-header__actions purchase-order-header__actions--save-cancel">
                            <div className="text-button-wrapper">
                                <Button
                                    disabled={!isFormDirty}
                                    buttonType={ButtonType.ICON_TEXT}
                                    dataId={createDataId(dataId, 'submitButton')}
                                    icon={ICONS.SAVE_24}
                                    onClick={() => formik.submitForm()}
                                    iconPlacement={ButtonIconPlacement.LEFT}
                                >
                                    {t('views.global.save')}
                                </Button>
                            </div>
                            <div className="text-button-wrapper discard">
                                <Button
                                    onClick={() => cancelEditting(false)}
                                    dataId={createDataId(dataId, 'cancelEdittingButton')}
                                    buttonType={ButtonType.ICON_TEXT}
                                    icon={ICONS.CANCEL_CHANGES_24}
                                    iconPlacement={ButtonIconPlacement.LEFT}
                                >
                                    {t('component.basicInfo.discardChanges')}
                                </Button>
                            </div>
                        </div>
                    </div>
                    <Form onChange={() => setIsFormDirty(true)}>
                        <div className="purchase-order-header__head">
                            <div className="purchase-order-header__head--left edit">
                                <div className="fixed-height supplier">
                                    <FormikField
                                        component={TypeaheadAsyncField}
                                        dataId={createDataId(dataId, 'supplierName')}
                                        toggleVisible
                                        name={fieldNames.Supplier}
                                        searchOnFocus
                                        onChange={(s: TypeaheadItem<SupplierDTO>) => changeSupplier(s, formik.values)}
                                        inputProps={{ label: t('component.additionalInfo.supplier'), type: TextInputType.COMPACT }}
                                        loadData={getSuppliersByName}
                                        itemToText={(s: SupplierDTO) => s?.Name}
                                        placeholder={t('component.additionalInfo.supplier')}
                                        wrapperClass="limit-width"
                                        preItemsListElement={<AddNewSupplierButton t={t} dataId={dataId} onClick={() => showAddOrEditSupplierModal(undefined)} />}
                                    />
                                </div>
                                <FormikField
                                    component={TextInputField}
                                    dataId={createDataId(dataId, 'purchaseOrderDescription')}
                                    name={fieldNames.Description}
                                    type={TextInputType.COMPACT}
                                    label={t('component.additionalInfo.description')}
                                    placeholder={t('component.additionalInfo.description')}
                                    wrapperClass="fixed-height limit-width"
                                />
                            </div>
                            <div className="purchase-order-header__head--right edit">
                                <FormikField
                                    component={TextInputField}
                                    dataId={createDataId(dataId, 'orderNumber')}
                                    name={fieldNames.PoNumber}
                                    disabled={isOrderNumberFieldDisabled}
                                    maxLength={100}
                                    type={TextInputType.COMPACT}
                                    label={t('component.purchaseOrder.header.orderNumber')}
                                    placeholder={t('component.purchaseOrder.header.orderNumber')}
                                    wrapperClass="fixed-height fixed-width"
                                />
                                <TypeaheadAsync
                                    dataId={createDataId(dataId, 'currency')}
                                    value={formik.values?.Currency}
                                    searchOnFocus
                                    toggleVisible
                                    onChange={(currency: TypeaheadItem<Currency>) => {
                                        formik.setFieldValue(fieldNames.Currency, {
                                            text: `${currency.value.Code} - ${getCurrencyName(currency.value.Code)}`,
                                            value: currency.value.Code,
                                        });
                                        formik.setFieldTouched(fieldNames.Currency, true);
                                        setIsFormDirty(true);
                                    }}
                                    inputProps={{ label: t('component.additionalInfo.currency'), type: TextInputType.COMPACT }}
                                    loadData={getCurrenciesByName}
                                    itemToText={(c: Currency) => `${c?.Code} - ${c?.Description}`}
                                    placeholder={t('component.additionalInfo.currency')}
                                    wrapperClass="fixed-height fixed-width"
                                />
                            </div>
                        </div>
                        <div className="purchase-order-header__fields">
                            <ul>
                                <TextInput
                                    dataId={createDataId(dataId, 'regNumber')}
                                    type={TextInputType.COMPACT}
                                    value={formik.values?.Supplier?.value?.RegistrationCode}
                                    disabled={true}
                                    label={t('component.purchaseOrder.header.regNumber')}
                                    placeholder={t('component.purchaseOrder.header.regNumber')}
                                    wrapperClass="fixed-height limit-width"
                                />
                                <TextInput
                                    dataId={createDataId(dataId, 'vatNumber')}
                                    type={TextInputType.COMPACT}
                                    value={formik.values?.Supplier?.value?.VatCode}
                                    disabled={true}
                                    label={t('component.purchaseOrder.header.supplierVatNumber')}
                                    placeholder={t('component.purchaseOrder.header.supplierVatNumber')}
                                    wrapperClass="fixed-height limit-width"
                                />
                                <TextInput
                                    dataId={createDataId(dataId, 'erpCode')}
                                    maxLength={35}
                                    type={TextInputType.COMPACT}
                                    value={formik.values?.Supplier?.value?.Code}
                                    disabled={true}
                                    label={t('component.purchaseOrder.header.eprCode')}
                                    placeholder={t('component.purchaseOrder.header.eprCode')}
                                    wrapperClass="fixed-height limit-width"
                                />
                            </ul>
                            <ul>
                                <FormikField
                                    component={Typeahead}
                                    dataId={createDataId(dataId, 'type')}
                                    name={fieldNames.Type}
                                    toggleVisible
                                    onChange={(type: TypeaheadItem<PurchaseOrderType>) => {
                                        setSelectedType(type);
                                        setIsFormDirty(true);
                                    }}
                                    value={selectedType}
                                    inputProps={{ label: t('view.PurchaseOrders.Type'), type: TextInputType.COMPACT }}
                                    items={orderTypesList}
                                    placeholder={t('view.PurchaseOrders.Type')}
                                    wrapperClass="fixed-height fixed-width"
                                />
                            </ul>
                            <ul>
                                <TextInput
                                    dataId={createDataId(dataId, 'createdDate')}
                                    value={formatDate(purchaseOrder?.DateCreated)}
                                    disabled={true}
                                    label={t('component.purchaseOrder.header.createdDate')}
                                    placeholder={t('component.purchaseOrder.header.createdDate')}
                                    wrapperClass="fixed-height limit-width"
                                    type={TextInputType.COMPACT}
                                />
                                <TextInput
                                    dataId={createDataId(dataId, 'createdBy')}
                                    maxLength={35}
                                    type={TextInputType.COMPACT}
                                    value={formik.values?.CreatedBy}
                                    disabled={true}
                                    label={t('view.PurchaseOrders.CreatedBy')}
                                    placeholder={t('view.PurchaseOrders.CreatedBy')}
                                    wrapperClass="fixed-height limit-width"
                                />
                            </ul>
                            <ul>
                                <TextInput
                                    dataId={createDataId(dataId, 'sumWithoutVat')}
                                    value={formatMoney(purchaseOrder?.TotalWithoutVat)}
                                    type={TextInputType.COMPACT}
                                    disabled={true}
                                    label={t('component.purchaseOrder.header.NetTotal')}
                                    placeholder={t('component.purchaseOrder.header.NetTotal')}
                                    replaceComma={true}
                                    wrapperClass="fixed-height"
                                />
                                <TextInput
                                    dataId={createDataId(dataId, 'vatSum')}
                                    value={formatMoney(purchaseOrder?.Total - purchaseOrder?.TotalWithoutVat)}
                                    disabled={true}
                                    type={TextInputType.COMPACT}
                                    label={t('component.purchaseOrder.header.VatTotal')}
                                    placeholder={t('component.purchaseOrder.header.VatTotal')}
                                    replaceComma={true}
                                    wrapperClass="fixed-height"
                                />
                                <TextInput
                                    dataId={createDataId(dataId, 'grandTotal')}
                                    value={formatMoney(purchaseOrder?.Total)}
                                    disabled={true}
                                    type={TextInputType.COMPACT}
                                    label={t('component.purchaseOrder.header.GrandTotal')}
                                    placeholder={t('component.purchaseOrder.header.GrandTotal')}
                                    replaceComma={true}
                                    wrapperClass="fixed-height"
                                />
                            </ul>
                        </div>
                    </Form>
                </WithTranslateFormErrors>
            )}
        </Formik>
    );
};

export default HeaderEditMode;
