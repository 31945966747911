import classNames from 'classnames';
import React from 'react';
import { Wrapper, WrapperState } from 'react-aria-menubutton';

import { LabelStyle } from '../TextInput/TextInput';

import { SelectOptionValue } from './SelectOption';

import './Select.scss';

export interface SelectWrapperProps<T> {
    children?: React.ReactNode | React.ReactNodeArray;
    className?: string;
    onSelection?: (value: SelectOptionValue<T>) => void;
    onMenuToggle?: (state: WrapperState) => void;
    closeOnSelection?: boolean;
    closeOnBlur?: boolean;
    tag?: string;
    label?: string;
    labelStyle?: LabelStyle;
    isOpen: boolean;
    isCompact?: boolean;
}

export const SelectWrapper = <T,>({ isCompact, isOpen, label, labelStyle, children, className, ...rest }: SelectWrapperProps<T>) => {
    const [isMenuOpen, setIsMenuOpen] = React.useState(isOpen);

    const classes = classNames('select', className, {
        'select--label-uppercase': labelStyle === LabelStyle.UPPERCASE,
        'select--has-focus': isMenuOpen,
    });
    return (
        <Wrapper
            className={classes}
            {...rest}
            onMenuToggle={(args: WrapperState) => {
                setIsMenuOpen(args.isOpen);
            }}
        >
            {!!label && <label className={classNames('select__label', { 'select__label--compact': isCompact })}>{label}</label>}
            {children}
        </Wrapper>
    );
};
